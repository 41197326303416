import { ItemType, SmartToolManagementTaskType, SmartToolManagementSubtaskType } from "@noccela/dna-iot-shared";
import React, { forwardRef, memo, useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { Group, Image as KonvaImage } from "react-konva";
import { TagIcon } from "./TagIcon";

export type MapItemProps = {
  x: number;
  y: number;
  r: number;
  name: string;
  id: number;
  itemType: ItemType;
  color: string | null;
  taskType: SmartToolManagementTaskType | null;
  subtaskType: SmartToolManagementSubtaskType | null;
};

const mapItemImageRepository = (() => {
  const repository: Record<string, HTMLImageElement> = {};
  const getHash = (x: any) => {
    return Object.entries(x)
      .sort((x, y) => {
        return x[0] > y[0] ? 1 : -1;
      })
      .map(([k, v]) => `${k};${v}`)
      .join(":");
  };

  return (props: React.ComponentProps<typeof TagIcon>) => {
    const propsHash = getHash(props);
    const cached = repository[propsHash];
    if (cached) return cached;

    const component = <TagIcon {...props} />;
    const x = ReactDOMServer.renderToString(component);
    const dataUri = `data:image/svg+xml;base64,${window.btoa(x)}`;
    const image = new Image();
    image.src = dataUri;
    repository[propsHash] = image;

    return image;
  };
})();

export const MapItem = memo(
  forwardRef<any, MapItemProps>(({ x, y, r, name, id, itemType, color: _color, taskType, subtaskType }, ref) => {
    const [image, setImage] = useState<HTMLImageElement>();
    const width = 20;
    const height = 24;
    const color =
      _color ||
      (() => {
        // TODO: Move color configuration up.
        // TODO: Icons, symbols.
        // const defaultColor = "black";
        if (itemType === ItemType.Forklift) {
          return "#FFA100";
        }
        if (itemType === ItemType.Tool) {
          if (subtaskType === SmartToolManagementSubtaskType.WaitingAtLocation) {
            return "#4B5055"; // Odottaa vuoroa.
          }
        }
        if (subtaskType && taskType) {
          if ([SmartToolManagementSubtaskType.MoveObjectToLocation, SmartToolManagementSubtaskType.MoveMaterialToLocation].includes(subtaskType)) {
            if (taskType === SmartToolManagementTaskType.Production) return "#FF007D";
            if (taskType === SmartToolManagementTaskType.Maintenance) return "#007D91";
          }
          if (subtaskType === SmartToolManagementSubtaskType.InTheWorks) {
            if (taskType === SmartToolManagementTaskType.Production) return "#FF007D"; // TODO: Differentiate visually.
            if (taskType === SmartToolManagementTaskType.Maintenance) return "#007D91";
          }
        }

        return "#F7F7F7";
      })();
    const text = (() => {
      return null;
    })();

    useEffect(() => {
      if (!subtaskType) return;

      const hasHole = [SmartToolManagementSubtaskType.MoveMaterialToLocation, SmartToolManagementSubtaskType.MoveObjectToLocation].includes(subtaskType) && itemType !== ItemType.Forklift;

      const image = mapItemImageRepository({
        fill: color,
        width,
        height,
        hole: hasHole,
        holeFill: hasHole ? "#F7F7F7" : undefined,
      });

      setImage(image);
    }, [color, taskType, itemType, subtaskType]);

    const x2 = x - Math.floor(width / 2);
    const y2 = y - height;
    return (
      <>
        <Group key={id} x={x2} y={y2} ref={ref}>
          {image && <KonvaImage image={image} />}
          {text}
        </Group>
      </>
    );
  }),
);

export const AnimatedMapItem = ({
  x,
  y,
  r,
  name,
  id,
  type,
  color,
  taskType,
  subtaskType
}: {
  x: number;
  y: number;
  r: number;
  name: string;
  id: number;
  type: ItemType;
  color: string | null;
  taskType: SmartToolManagementTaskType;
  subtaskType: SmartToolManagementSubtaskType;
}) => {
  const [initialXY, setXY] = useState<[number, number]>();
  const rootRef = useRef(null);
  useEffect(() => {
    if (x && y && !initialXY) setXY([x, y]);
    const el: any = rootRef.current;
    if (!el) return;
    el.to({
      duration: 0.5,
      x,
      y,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [x, y]);
  if (!initialXY) return null;
  return (
    <MapItem
      ref={rootRef}
      x={initialXY[0]}
      y={initialXY[1]}
      r={r}
      name={name}
      itemType={type}
      id={id}
      color={color}
      taskType={taskType}
      subtaskType={subtaskType}
    />
  );
};

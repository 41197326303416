import { useRecoilValue } from "recoil";
import Kanban from "../components/Kanban";
import { tasksListAtom } from "../state/tasks";
import { ServiceProps } from "../types";

type Props = ServiceProps & {};
export const KanbanView = ({ services }: Props) => {
  const tasks = useRecoilValue(tasksListAtom);
  console.log({
    tasks,
  });

  return (
    <div className="flex flex-col justify-center">
      <h1 className="text-3xl font-bold pb-6">{services.t({ id: "Worksheet" })}</h1>
      <Kanban tasks={tasks} services={services} />
    </div>
  );
};

export default KanbanView;

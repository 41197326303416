import {
  AuthInfo,
  FetchSocketUrlResponse,
  RefreshTokenResponse,
  UserInfoUserResponse,
  UserInfoWorkerResponse,
} from "./types";

export const getRefreshToken =
  ({ url }: { url: string }) =>
    async ({ refreshToken }: { refreshToken: string }): Promise<RefreshTokenResponse | Error> => {
      const body = {
        grant_type: "refresh_token",
        client_id: "dna-iot",
        refresh_token: refreshToken,
      };
      const r = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (r.status !== 200) {
        console.error({ status: r.status });
        return Error("Failed to fetch refresh token");
      }
      const {
        token_type: tokenType,
        expires_in: expiresIn,
        access_token: newAccessToken,
        refresh_token: newRefreshToken,
      } = await r.json();
      return {
        expiresIn,
        refreshToken: newRefreshToken,
        accessToken: newAccessToken,
      };
    };

export const fetchSocketUrl = async ({
  password,
  username,
  authUrl,
  infoUrl,
}: AuthInfo & { authUrl: string; infoUrl: string }): Promise<FetchSocketUrlResponse | Error> => {
  const authBody = {
    grant_type: "password",
    client_id: "dna-iot",
    username: username,
    password: password,
  };
  const r1 = await fetch(authUrl, {
    method: "POST",
    body: JSON.stringify(authBody),
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (r1.status !== 200) {
    console.error({
      status: r1.status,
    });
    return Error(`Failed to authenticate`);
  }

  const {
    token_type: tokenType,
    expires_in: expiresIn,
    access_token: accessToken,
    refresh_token: refreshToken,
  } = await r1.json();

  const infoBody = {
    access_token: accessToken,
  };
  const r2 = await fetch(infoUrl, {
    method: "POST",
    body: JSON.stringify(infoBody),
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (r2.status !== 200) {
    console.error({
      status: r2.status,
    });
    return Error(`Failed to fetch socket URL`);
  }

  const { user, workers } = (await r2.json()) as {
    user: UserInfoUserResponse;
    workers: UserInfoWorkerResponse[];
  };
  const {
    id: userId,
    name: userName,
    email: userEmail,
    userName: userName2, // What is this used for?
  } = user;
  if (!workers.length) {
    return Error("No worker nodes");
  }
  // Pick first worker node, TODO: This has to change later to support multiple nodes.
  const { id: workerId, name: workerName, url: workerUrl, permissions: workerPermissions } = workers[0];

  return {
    expiresIn,
    expiresTs: Math.floor(Date.now() + expiresIn * 1000),
    workerId,
    workerName,
    workerUrl,
    workerPermissions,
    userId,
    userName,
    userEmail,
    accessToken,
    refreshToken,
  };
};

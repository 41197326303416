import clsx from "clsx";
import { isEqual } from "lodash";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { MdExpandMore } from "react-icons/md";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import AddObject from "../components/AddObject";
import { AutoResizer, ResizerCallbackArguments } from "../components/Autoresizer";
import Button from "../components/Button";
import EditContent from "../components/EditContent";
import { ItemList } from "../components/ItemList";
import { ListHead } from "../components/ListHead";
import ModalWindow from "../components/ModalWindow";
import OutsideAlerter from "../components/OutsideAlerter";
import TagDetails from "../components/TagDetails";
import { useFormFocus, useTranslations } from "../hooks";
import { currentItemIdAtom, filteredItemsOrderListAtom, itemFilterAtom, linkedTaskAtom } from "../state/items";
import { errorMessageAtom, isDetailsOpenAtom } from "../state/misc";
import styles from "../styles/ListView.module.css";
import { ItemKey, ServiceProps } from "../types";
import { GetListItemsParam } from "../util/list";

const getListHash = (sorted: boolean) => (x: any[]) => (sorted ? [...x].sort() : x).join(";");
const getDictHash = (x: Record<string, any>) =>
  Object.entries(x)
    .sort((a, b) => {
      return a[0] > b[0] ? 1 : -1;
    })
    .map((x) => `${x[0]}:${Boolean(x[1])}`)
    .join(";");

export const MemoizedListView = memo(
  ({ filteredItemIds, services }: ServiceProps & { filteredItemIds: ItemKey[] }) => {
    const t = useTranslations();
    const [itemFilter, setItemFilter] = useRecoilState(itemFilterAtom);
    const currentItemId = useRecoilValue(currentItemIdAtom);
    const isDetailsOpen = useRecoilValue(isDetailsOpenAtom);
    const setError = useSetRecoilState(errorMessageAtom);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [isAddingObj, setIsAddingObj] = useState<boolean>(false);
    const [isAddingTask, setIsAddingTask] = useState<boolean>(false);
    const [selectedItems, setSelectedItems] = useState<ItemKey[]>([]);
    const [tagSearchText, setTagSearchText] = useState(itemFilter.name || "");
    const selectedItemsHash = getListHash(true)(selectedItems);
    const filteredItemsHash = getListHash(false)(filteredItemIds);
    const servicesHash = getDictHash(services);
    const linkedTask = useRecoilValue(linkedTaskAtom(currentItemId));

    const ref = useRef(null);

    const onSearch = useCallback(() => {
      setItemFilter({
        name: tagSearchText,
      });
    }, [tagSearchText, setItemFilter]);

    // Trigger search on enter.
    const { setFormFocus } = useFormFocus({ onSubmit: onSearch });

    // Trigger search after change and interval.
    useEffect(() => {
      const to = setTimeout(() => {
        onSearch();
      }, 500);
      return clearTimeout.bind(null, to);
    }, [tagSearchText, onSearch]);

    const getItems = useCallback(
      ({ offset, limit }: GetListItemsParam): ItemKey[] => filteredItemIds.slice(offset, offset + limit),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [filteredItemsHash],
    );

    const selectItem = useCallback(
      (id: ItemKey) => {
        setSelectedItems((old) => {
          if (selectedItems.includes(id)) {
            return old.filter((oldId) => oldId !== id);
          }
          return [...old, id];
        });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [selectedItemsHash],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const isSelected = useCallback((id: ItemKey) => selectedItems.includes(id), [selectedItemsHash]);

    const cb = useCallback(
      ({ width, height, containerClassName }: ResizerCallbackArguments & { containerClassName: string }) => (
        <table className={clsx("w-full h-full flex flex-col", containerClassName)}>
          <ListHead />
          <ItemList
            width={width}
            height={height}
            getItems={getItems}
            itemProps={{ setShowModal: setIsModalOpen, services: services, selectItem, isSelected }}
          />
        </table>
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [getItems, selectItem, servicesHash, isSelected],
    );

    const onDeleteItem = async () => {
      for (const selectedItem of selectedItems) {
        try {
          if (selectedItem) {
            const res = await services.api?.removeItem({ id: selectedItem });
          }
        } catch (e) {
          console.error(e);
          setError({
            message: t({
              id: "Error.DeletingTask",
            }),
            details: (e as any)?.message,
          });
        }
      }
      setSelectedItems([]);
    };

    return (
      <>
        {isDetailsOpen ? (
          <TagDetails services={services} />
        ) : (
          <>
            <div className="grid grid-cols-2 pb-6">
              <h1 className="text-3xl font-bold">{t({ id: "Page.Orders" })}</h1>
              <div className="w-full flex space-x-4">
                <div className="flex w-full">
                  <input
                    value={tagSearchText}
                    onChange={({ target: { value } }) => setTagSearchText(value)}
                    placeholder={t({ id: "Actions.SearchTag" }).toUpperCase()}
                    onFocus={() => setFormFocus((x) => x + 1)}
                    onBlur={() => setFormFocus((x) => x - 1)}
                    className="px-4"
                  />
                  <Button onClick={onSearch} variant="primary" text={t({ id: "Actions.Search" }).toUpperCase()} />
                </div>
                <Button
                  variant="secondary"
                  text={t({ id: "Actions.Add" }).toUpperCase()}
                  icon={<MdExpandMore className="text-2xl ml-auto" />}
                  className="flex items-center pl-4 pr-2 relative"
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  {showDropdown && (
                    <OutsideAlerter
                      parentRef={ref}
                      className="absolute z-10 w-auto flex drop-shadow top-10 right-0"
                      onClickOutside={() => setShowDropdown(false)}
                    >
                      <div className="flex flex-col w-full">
                        <button
                          className="hover:bg-dna-vaaleanharmaa px-4 py-2 border-b border-dna-vaaleanharmaa bg-white"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowDropdown(false);
                            setIsAddingObj(true);
                          }}
                        >
                          {t({ id: "Type.Object" })}
                        </button>
                        <button
                          className="hover:bg-dna-vaaleanharmaa px-4 py-2 bg-white"
                          onClick={(e) => {
                            setShowDropdown(false);
                            setIsAddingTask(true);
                          }}
                        >
                          {t({ id: "Type.Order" })}
                        </button>
                      </div>
                    </OutsideAlerter>
                  )}
                </Button>
                <Button
                  variant="secondary"
                  text={t({ id: "Actions.Delete" }).toUpperCase()}
                  onClick={() => onDeleteItem()}
                  disabled={!selectedItems.length}
                />
              </div>
            </div>
            <AutoResizer
              key={filteredItemsHash}
              className={clsx("h-full bg-white drop-shadow", isModalOpen ? "pointer-events-none" : "", styles.container)}
              callback={cb}
            />
          </>
        )}
        {isModalOpen && (
          <ModalWindow>
            <EditContent
              key={`dialog-1-${currentItemId}`}
              setIsModalOpen={setIsModalOpen}
              isNew={false}
              canModifyTask={!!linkedTask}
              services={services}
            />
          </ModalWindow>
        )}
        {isAddingObj && (
          <ModalWindow>
            <AddObject key={`dialog-2-${currentItemId}`} setIsModalOpen={setIsAddingObj} services={services} />
          </ModalWindow>
        )}
        {isAddingTask && (
          <ModalWindow>
            <EditContent
              key={`dialog-3-${currentItemId}`}
              setIsModalOpen={setIsAddingTask}
              isNew={true}
              canModifyTask
              services={services}
            />
          </ModalWindow>
        )}
      </>
    );
  },
  (prevProps, nextProps) => {
    if (prevProps.services !== nextProps.services) return false;
    if (!isEqual(prevProps.filteredItemIds, nextProps.filteredItemIds)) {
      return false;
    }
    return true;
  },
);

export const ListView = ({ services }: ServiceProps) => {
  const filteredItemIds = useRecoilValue(filteredItemsOrderListAtom);
  return <MemoizedListView services={services} filteredItemIds={filteredItemIds} />;
};

export default ListView;

import clsx from "clsx";
import { MdOutlineClose } from "react-icons/md";
import { useRecoilState } from "recoil";
import { errorMessageAtom } from "../state/misc";
import { ServiceContainer } from "../types";
import ModalWindow from "./ModalWindow";

export const Dialog = ({
  children,
  className,
  onClose,
}: {
  children: React.ReactNode;
  className: string;
  onClose: () => void;
}) => {
  return (
    <div className={clsx("bg-dna-puhdasvalkoinen flex flex-col relative m-auto p-4 z-20 drop-shadow", className)}>
      <div className="flex">
        <button className="hover:bg-dna-vaaleanharmaa p-1 rounded ml-auto text-2xl" onClick={onClose}>
          <MdOutlineClose />
        </button>
      </div>
      {children}
    </div>
  );
};

export const ErrorMessageDialog = ({
  title,
  details,
  message,
  onClose,
}: {
  title?: string;
  details?: string;
  message: string;
  onClose: () => void;
}) => {
  return (
    <ModalWindow>
      <Dialog onClose={onClose} className="max-w-md">
        <header>
          <h1 className="text-2xl font-medium mb-10 text-center text-dna-kuumapinkki-dark">{title}</h1>
        </header>
        <p className="px-5">{message}</p>
        {details && <code className="mt-10 overflow-y-auto max-h-40 bg-dna-backgroundgray p-4 font-mono">{details}</code>}
      </Dialog>
    </ModalWindow>
  );
};

export const StatefulErrorMessage = ({ services: { t } }: { services: ServiceContainer }) => {
  const [error, setErrorState] = useRecoilState(errorMessageAtom);
  if (!error) return null;
  const { title, details, message } = error;
  const handleClose = () => {
    setErrorState(null);
  };
  return (
    <ErrorMessageDialog
      title={title || t({ id: "Error.DialogDefaultTitle" })}
      details={details}
      message={message}
      onClose={handleClose}
    />
  );
};

import { SmartToolManagementSubtaskType, SmartToolManagementTaskType } from "@noccela/dna-iot-shared";
import { Translations } from "../hooks";
import { SmartToolManagementTask } from "../types";

export const calcWidth = (amountToProduce: number, amountProduced: number) => {
  if (amountToProduce === 0 || amountProduced === 0) {
    return 0;
  }
  const percentage = Math.round((amountProduced * 100) / amountToProduce);
  return percentage;
};

export const delay: (t?: number) => Promise<void> = async (t) =>
  new Promise((r) => (!t ? requestAnimationFrame(() => r()) : setTimeout(r, t)));

export const getStateTranslation = (task: SmartToolManagementTask | null, t: Translations) => {

  let ta = task?.subtasks.find(a => a.start != null && a.stop == null);
  let state: SmartToolManagementSubtaskType | undefined = ta?.type;
  if (task?.type === SmartToolManagementTaskType.Production && state === SmartToolManagementSubtaskType.MoveObjectToLocation) {
    return t({ id: "TaskState.MoveToWork" });
  } else if (task?.type === SmartToolManagementTaskType.Production && state === SmartToolManagementSubtaskType.WaitingAtLocation) {
    return t({ id: "TaskState.Waiting" });
  } else if (task?.type === SmartToolManagementTaskType.Production && state === SmartToolManagementSubtaskType.InTheWorks) {
    return t({ id: "TaskState.Active" });
  } else if (state === SmartToolManagementSubtaskType.MoveFromLocation) {
    return t({ id: "TaskState.MoveToWarehouse" });
  } else if (task?.type === SmartToolManagementTaskType.Maintenance && state === SmartToolManagementSubtaskType.MoveObjectToLocation) {
    return t({ id: "TaskState.MoveToMaintenance" });
  } else if (task?.type === SmartToolManagementTaskType.Maintenance && state === SmartToolManagementSubtaskType.InTheWorks) {
    return t({ id: "TaskState.InMaintenance" });
  } else {
    return t({ id: "Type.Unknown" });
  }
};

import NccModels, { SmartToolManagementTaskType } from "@noccela/dna-iot-shared";
import { BackendApi } from "./backend/state-handler";
import { Translations } from "./hooks";

export const COLORS = {
	Dna: {
		VedenSininen: "#37424a",
		MeloninKeltainen: "#FFA100",
		KivenHarmaa: "#4B5055",
		VaaleanHarmaa: "#E0E1DD",
		Musta: "#000000",
		PuhdasValkoinen: "#FFFFFF",
		KuumaPinkki: "#d52b1e",
		BackgroundGray: "#f2f2f2",
		TextColor: "#434a54",
	},
};

export enum Pages {
	Empty = "empty",
	List = "list",
	Map = "map",
	Kanban = "kanban",
	Dashboard = "dashboard",
}

export enum BackendState {
	NotConnected = "NotConnected",
	Authenticating = "FetcingToken",
	Refreshing = "RefreshingToken",
	FetchingUserInfo = "FetchingUserInfo",
	Connecting = "SocketConnecting",
	Connected = "SocketConnected",
	ConnectedAndAuthenticated = "SocketConnectedAndAuthenticated",
	Closed = "SocketClosed",
	Communicating = "SocketCommunicating",
	AuthenticationError = "AuthError",
	SocketError = "SocketError",
}

export enum Languages {
	Finnish = "fi",
}

export type Sorter = "desc" | "asc";

export type Blueprint = {
	contentType: string;
	content: string;
};
export type Floor = NccModels.Floor & {};
export type FloorKey = NccModels.Floor["id"];

export type Area = NccModels.Area & {};
export type AreaKey = NccModels.Area["id"];

export type Alert = Omit<NccModels.Alert, "timestamp"> & {
	timestamp: Date;
};
export type AlertKey = NccModels.Alert["id"];
export type AlertFilter = {};
export type AlertSorter = {
	id?: Sorter;
};

export type SmartToolManagementTask = Omit<NccModels.SmartToolManagementTask, "targetTimestamp" | "subtasks"> & {
	targetTimestamp: Date;
	subtasks: SmartToolManagementSubTask[];
	areaId: number;
};

export type SmartToolManagementSubTask = Omit<NccModels.SmartToolManagementSubtask, "start" | "stop" | "targetStart" | "targetStop" | "reportedStart" | "reportedStop"> & {
	start: Date | null;
	stop: Date | null;
	targetStart: Date | null;
	targetStop: Date | null;
	reportedStart: Date | null;
	reportedStop: Date | null;
};

export type TaskKey = NccModels.Task["id"];
export type TaskFilter = {
	type?: SmartToolManagementTaskType;
	areaId?: AreaKey;
};
export type TaskSorter = {
	id?: Sorter;
	targetTimestamp?: Sorter;
};

export type Item = Omit<NccModels.Item, "lastActivity"> & {
	lastActivity: Date | null;
};
export type ItemKey = NccModels.Item["id"];
export type ItemFilter = {
	name?: string;
};
export type ItemSorter = {
	id?: Sorter;
	lastActivity?: Sorter;
	name?: Sorter;
};

export type ItemWithTask = Item & {
	task: SmartToolManagementTask | null;
};

type ConsoleLogParams = typeof console["log"];
type FnLog = ConsoleLogParams;
export type Logger = {
	log: FnLog;
	warn: FnLog;
	error: FnLog;
};

export type ServiceContainer = {
	logger: Logger;
	api: BackendApi | null;
	constants: {
		dev: boolean;
	};
	t: Translations;
	colors: typeof COLORS;
};

export type ServiceProps = {
	services: ServiceContainer;
};

export type FnSetConnectionState = (x: BackendState) => void;

export type ErrorMessage = {
	title?: string;
	message: string;
	details?: string;
};

import { Translations } from "../hooks";
import { FormattedRelativeTime } from "react-intl";

export const getLastSeenProperties = (now: Date) => (timestamp: Date | null) => {
  if (!timestamp)
    return {
      lastSeenSeconds: null,
      lastSeenMinutes: null,
      lastSeenHours: null,
      lastSeenDays: null,
      showLastSeen: false,
      showLastSeenSeconds: false,
      showLastSeenMinutes: false,
      showLastSeenHours: false,
      showLastSeenDays: false,
    };

  const lastSeenPosix = timestamp.getTime();
  const lastSeenDifference = now.getTime() - lastSeenPosix;
  const lastSeenSeconds = lastSeenDifference / 1000;
  const lastSeenMinutes = lastSeenSeconds / 60;
  const lastSeenHours = lastSeenMinutes / 60;
  const lastSeenDays = lastSeenHours / 24;

  if (lastSeenDifference <= 0)
    return {
      lastSeenSeconds: 0,
      lastSeenMinutes: 0,
      lastSeenHours: 0,
      lastSeenDays: 0,
      showLastSeen: true,
      showLastSeenSeconds: true,
      showLastSeenMinutes: false,
      showLastSeenHours: false,
      showLastSeenDays: false,
    };

  const showLastSeen = typeof lastSeenSeconds === "number";
  const showLastSeenSeconds = Boolean(showLastSeen && lastSeenSeconds < 60);
  const showLastSeenMinutes = Boolean(!showLastSeenSeconds && lastSeenMinutes && lastSeenMinutes < 60);
  const showLastSeenHours = Boolean(!showLastSeenMinutes && lastSeenHours && lastSeenHours < 24);
  const showLastSeenDays = Boolean(!showLastSeenHours && lastSeenDays);

  return {
    lastSeenSeconds: -1 * Math.round(lastSeenSeconds),
    lastSeenMinutes: -1 * Math.round(lastSeenMinutes),
    lastSeenHours: -1 * Math.round(lastSeenHours),
    lastSeenDays: -1 * Math.round(lastSeenDays),
    showLastSeen,
    showLastSeenSeconds,
    showLastSeenMinutes,
    showLastSeenHours,
    showLastSeenDays,
  };
};

export const LastActivityTimestamp = ({
  dateString,
  lastSeenDays,
  lastSeenHours,
  lastSeenMinutes,
  lastSeenSeconds,
  showLastSeen,
  showLastSeenDays,
  showLastSeenHours,
  showLastSeenMinutes,
  showLastSeenSeconds,
  t,
  className,
}: {
  dateString: string;
  lastSeenDays: number | null;
  lastSeenHours: number | null;
  lastSeenMinutes: number | null;
  lastSeenSeconds: number | null;
  showLastSeen: boolean;
  showLastSeenDays: boolean;
  showLastSeenHours: boolean;
  showLastSeenMinutes: boolean;
  showLastSeenSeconds: boolean;
  t: Translations;
  className?: string;
}) => {
  return (
    <span title={dateString || ""} className={className}>
      {showLastSeen ? (
        typeof lastSeenSeconds === "number" && lastSeenSeconds > -10 ? (
          t({ id: "Details.LastSeenNow" })
        ) : showLastSeenSeconds ? (
          <FormattedRelativeTime value={lastSeenSeconds || 0} unit="second" />
        ) : showLastSeenMinutes ? (
          <FormattedRelativeTime value={lastSeenMinutes || 0} unit="minute" />
        ) : showLastSeenHours ? (
          <FormattedRelativeTime value={lastSeenHours || 0} unit="hour" />
        ) : showLastSeenDays ? (
          <FormattedRelativeTime value={lastSeenDays || 0} unit="day" />
        ) : null
      ) : (
        t({ id: "Details.LastSeenNever" })
      )}
    </span>
  );
};

export const LastActivityTimestampFromDate = ({
  timestamp,
  t,
  className,
}: {
  timestamp: Date | null;
  t: Translations;
  className?: string;
}) => {
  const {
    lastSeenSeconds,
    lastSeenMinutes,
    lastSeenHours,
    lastSeenDays,
    showLastSeen,
    showLastSeenDays,
    showLastSeenHours,
    showLastSeenMinutes,
    showLastSeenSeconds,
  } = getLastSeenProperties(new Date())(timestamp);

  const lastActivityLocalizedString = timestamp
    ? Intl.DateTimeFormat("fi-FI", { dateStyle: "short", timeStyle: "short" }).format(new Date())
    : null;

  return (
    <LastActivityTimestamp
      t={t}
      dateString={lastActivityLocalizedString || ""}
      lastSeenDays={lastSeenDays}
      lastSeenHours={lastSeenHours}
      lastSeenMinutes={lastSeenMinutes}
      lastSeenSeconds={lastSeenSeconds}
      showLastSeen={showLastSeen}
      showLastSeenDays={showLastSeenDays}
      showLastSeenHours={showLastSeenHours}
      showLastSeenMinutes={showLastSeenMinutes}
      showLastSeenSeconds={showLastSeenSeconds}
      className={className}
    />
  );
};

import { ItemType, SmartToolManagementSubtaskType, SmartToolManagementTaskType } from "@noccela/dna-iot-shared";
import clsx from "clsx";
import { isEqual } from "lodash";
import { ServiceProps } from "../../types";
import { TagIcon } from "./TagIcon";

export type MapFilter = {
  itemType?: ItemType | ItemType[];
  subtaskType?: SmartToolManagementSubtaskType | SmartToolManagementSubtaskType[];
  taskType?: SmartToolManagementTaskType | SmartToolManagementTaskType[];
};

export const MapFilters = ({
  filters,
  setFilter,
  services: { t, colors },
}: ServiceProps & { filters: MapFilter[]; setFilter: (x: MapFilter) => void }) => {
  type Color = string;
  type Label = string;

  const FilterElement = ({
    active,
    onClick,
    color,
    label,
    hole,
  }: {
    active: boolean;
    onClick: () => void;
    color: Color;
    label: Label;
    hole: boolean;
  }) => {
    return (
      <button
        onClick={onClick}
        className={clsx(
          "p-1 flex items-center gap-x-3 hover:cursor-pointer text-md select-none hover:underline font-bold",
          active && "outline-dotted outline-2",
        )}
      >
        <TagIcon width={20} height={24} fill={color} hole={hole} />{" "}
        <span className={clsx("flex-auto text-almostblack")}>{label}</span>
      </button>
    );
  };

  const filterButtons: { color: Color; label: Label; filter: MapFilter; hole: boolean }[] = [
    {
      filter: {
        itemType: ItemType.Forklift,
      },
      color: colors.Dna.MeloninKeltainen,
      label: t({ id: "Type.ForkLift" }),
      hole: false,
    },
    {
      filter: {
        subtaskType: SmartToolManagementSubtaskType.WaitingAtLocation,
      },
      color: "#333",
      label: t({ id: "TaskState.Waiting" }),
      hole: false,
    },
    {
      filter: {
        subtaskType: [SmartToolManagementSubtaskType.MoveMaterialToLocation, SmartToolManagementSubtaskType.MoveObjectToLocation],
        taskType: SmartToolManagementTaskType.Production,
      },
      color: colors.Dna.KuumaPinkki,
      label: t({ id: "TaskState.MoveToWork" }),
      hole: true,
    },
    {
      filter: {
        subtaskType: SmartToolManagementSubtaskType.InTheWorks,
        taskType: SmartToolManagementTaskType.Production,
      },
      color: colors.Dna.KuumaPinkki,
      label: t({ id: "TaskState.Active" }),
      hole: false,
    },
    {
      filter: {
        subtaskType: [SmartToolManagementSubtaskType.MoveMaterialToLocation, SmartToolManagementSubtaskType.MoveObjectToLocation],
        taskType: SmartToolManagementTaskType.Maintenance,
      },
      color: colors.Dna.VedenSininen,
      label: t({ id: "TaskState.MoveToMaintenance" }),
      hole: true,
    },
    {
      filter: {
        subtaskType: SmartToolManagementSubtaskType.InTheWorks,
        taskType: SmartToolManagementTaskType.Maintenance,
      },
      color: colors.Dna.VedenSininen,
      label: t({ id: "TaskState.InMaintenance" }),
      hole: false,
    },
  ];

  return (
    <div className="w-auto flex flex-row gap-x-8">
      {filterButtons.map(({ filter: localFilter, color, label, hole }) => (
        <FilterElement
          key={label}
          active={filters.some((f) => isEqual(localFilter, f))}
          color={color}
          label={label}
          onClick={() => setFilter(localFilter)}
          hole={hole}
        />
      ))}
    </div>
  );
};

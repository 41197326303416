import { Sorter } from "../types";

export const sortByDateProp =
  ({ prop, direction }: { prop: string; direction: Sorter }) =>
  (a: any, b: any) => {
    const asc = direction === "asc";
    const ts1 = a[prop]?.valueOf() || 0;
    const ts2 = b[prop]?.valueOf() || 0;
    if (ts1 && !ts2) return -1;
    if (ts2 && !ts1) return 1;
    if (asc) return ts1 - ts2;
    return ts2 - ts1;
  };

import React from "react";
import { createInfiniteList, GetListItemsParam } from "../util/list";
import { AutoResizer, ResizerCallbackArguments } from "./Autoresizer";
import { ItemListItem, TestListItem } from "./ItemListItem";

const getEmptyView = () => {
  return <div className="flex w-full justify-center mt-10 font-bold text-xl">Ei tuloksia</div>;
};
const getErrorView = () => <>Error</>;

type ItemListProps = Parameters<ReturnType<typeof createInfiniteList>>[0];

export const ItemList = React.memo(
  createInfiniteList<any>({
    type: "fixed",
    itemSize: 75, // Fixed list item height!
    getEmptyView,
    getErrorView,
    batchSize: 50,
    Item: ItemListItem,
  }) as React.FC<ItemListProps>,
);

type TestType = { i: number };
const _TestList = React.memo(
  createInfiniteList<any>({
    type: "fixed",
    itemSize: 120,
    getEmptyView,
    getErrorView,
    batchSize: 50,
    Item: TestListItem,
  }) as React.FC<ItemListProps>,
);

export const TestList = () => {
  const items: TestType[] = [...Array(1000)].map((_, i) => ({
    i,
  }));
  const getItems = ({ offset, limit }: GetListItemsParam) => {
    return items.slice(offset, offset + limit);
  };
  const cb = ({ width, height }: ResizerCallbackArguments) => (
    // eslint-disable-next-line react/jsx-pascal-case
    <_TestList width={width} height={height} getItems={getItems} />
  );
  return <AutoResizer className="flex-auto" callback={cb} />;
};

import { identity } from "lodash";
import { atom, selector } from "recoil";
import { Alert, AlertFilter, AlertKey, AlertSorter } from "../types";

export const alertsAtom = atom<Record<AlertKey, Alert>>({
  key: "alerts",
  default: {},
});
export const alertSorter = atom<AlertSorter>({
  key: "alerts_sort",
  default: {
    id: "asc",
  },
});
export const alertsOrderAtom = selector<AlertKey[]>({
  key: "alerts_order",
  get: ({ get }) => {
    const alerts = get(alertsAtom);
    const sort = get(alertSorter);
    const sorted = Object.values(alerts).sort((a, b) => {
      if (sort.id) {
        if (sort.id === "asc") return a.id - b.id;
        if (sort.id === "desc") return b.id - a.id;
      }
      return a.id - b.id;
    });
    return sorted.map((s) => s.id);
  },
});
export const alertsListAtom = selector<Alert[]>({
  key: "alerts_list",
  get: ({ get }) => {
    const alerts = get(alertsAtom);
    const alertsOrder = get(alertsOrderAtom);
    return alertsOrder.map((t) => alerts[t]).filter(identity);
  },
});
export const alertsFilter = atom<AlertFilter>({
  key: "alerts_filter",
  default: {},
});
export const filteredAlertsListAtom = selector<Alert[]>({
  key: "alerts_list_filtered",
  get: ({ get }) => {
    const alerts = get(alertsListAtom);
    const filter = get(alertsFilter);
    // TODO: Implement filtering.
    return alerts;
  },
});

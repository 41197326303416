import { isEqual } from "lodash";
import { useEffect, useRef, useState, useCallback } from "react";
import { useRecoilValue } from "recoil";
import { itemsListAtom } from "./state/items";
import { tasksAtom } from "./state/tasks";
import { translationsAtom, TranslationsKeys } from "./state/translations";
import { ItemKey, ItemWithTask, SmartToolManagementTask } from "./types";

export function useDeepCompareMemoize(value: any) {
  const ref = useRef();
  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}

export type Translations = ReturnType<typeof useTranslations>;
export const useTranslations = () => {
  const translations = useRecoilValue(translationsAtom);
  return ({ id }: { id: TranslationsKeys }) => {
    return translations[id] || `MISSING_TRANSLATION: ${id}`;
  };
};

export const useAsyncError = () => {
  const [_, setError] = useState();
  return useCallback(
    e => {
      setError(() => {
        throw e;
      });
    },
    [setError],
  );
};

export function useIsMounted(ref: React.MutableRefObject<boolean>) {
  if (!ref) throw Error("Null ref");
  useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);
}

export function useItemsWithTasks(): ItemWithTask[] {
  const items = useRecoilValue(itemsListAtom);
  const tasks = useRecoilValue(tasksAtom);
  const tasksByItemId: Record<ItemKey, SmartToolManagementTask> = Object.fromEntries(
    Object.values(tasks)
      .filter((t) => t.itemId)
      .map((t) => [t.itemId, t]),
  );
  const result: ItemWithTask[] = items.map((i) => {
    const itemId = i.id;
    const task = tasksByItemId[itemId];
    return {
      ...i,
      task,
    } as ItemWithTask;
  });
  return result;
}

export const useFormFocus = ({ onSubmit }: { onSubmit: () => void }) => {
  const [formFocus, setFormFocus] = useState(0);
  const hasFormFocus = formFocus > 0;
  useEffect(() => {
    const handler = (e: any) => {
      if (e.key === "Enter" && hasFormFocus) {
        onSubmit();
      }
    };
    document.addEventListener("keydown", handler);
    return document.removeEventListener.bind(document, "keydown", handler);
  }, [hasFormFocus, onSubmit]);
  return { hasFormFocus, setFormFocus };
};

import {
  AlertChangesResponse,
  AuthenticateRequest,
  GetModulesResponse,
  CreateItemRequest,
  CreateTaskRequest,
  SmartToolManagementCreateTaskRequest,
  SmartToolManagementEditTaskRequest,
  SmartToolManagementUpdateTaskRequest,
  EditItemRequest,
  EditTaskRequest,
  GetAlertsResponse,
  GetItemsResponse,
  GetLayoutImageRequest,
  GetLayoutImageResponse,
  GetLayoutResponse,
  GetTasksResponse,
  GetTasksRequest,
  ItemChangesResponse,
  MessageAction,
  RemoveItemRequest,
  RemoveTaskRequest,
  SetItemLedRequest,
  TaskChangesResponse,
  UpdateTaskRequest,
  RegisterToTaskChangesRequest,
  GetWorkShiftsResponse,
  GetWorkShiftsRequest,
  RegisterToWorkShiftChangesRequest,
  WorkShiftChangesResponse,
  ModuleType
} from "@noccela/dna-iot-shared";
import { isNil } from "lodash";
import { BackendState, FnSetConnectionState, Logger } from "../types";
import { delayAsync, delayUntil } from "../util/misc";
import {
  EmptyRequest,
  EmptyResponse,
  FnRefreshToken,
  RefreshTokenResponse,
  RetryState,
  WebSocketRequest,
  WebSocketRequestMessage,
  WebSocketResponse,
  WebSocketResponseMessage,
  WebSocketResponseSuccess,
  WebSocketServerMessage,
} from "./types";
import env from "react-dotenv";


const getNextRetryState = (oldRetryState: RetryState): RetryState => {
  const nextInterval = Math.max(
    oldRetryState.retryIntervalMin,
    Math.min(oldRetryState.currentInterval + oldRetryState.retryIntervalIncrease),
  );
  return {
    ...oldRetryState,
    currentInterval: nextInterval,
    nextRetry: Date.now() + nextInterval,
  };
};

export const createPersistentBackendAdapter =
  ({
    fnRandomIdentifier,
    fnRefreshToken,
    logger,
    setConnectionState,
    onLogOut
  }: {
    fnRandomIdentifier: () => string;
    fnRefreshToken: FnRefreshToken;
    logger: Logger;
    setConnectionState: FnSetConnectionState;
    onLogOut: () => void
  }) =>
    async ({
      accessToken: originalAccessToken,
      refreshToken: originalRefreshToken,
      expiresTs: originalExpiresTs
    }: {
      accessToken: string;
      refreshToken: string;
      expiresTs: number;
    }) => {
      let rt = originalRefreshToken;
      let at = originalAccessToken;
      let expTs = originalExpiresTs;
      let nextRefreshTimeout: any = null;
      let pingTimeout: any = null;

      type FnItemChangesHandler = (x: ItemChangesResponse) => void;
      type FnTaskChangesHandler = (x: TaskChangesResponse) => void;
      type FnAlertChangesHandler = (x: AlertChangesResponse) => void;
      type FnWorkShiftChangesHandler = (x: WorkShiftChangesResponse) => void;
      type FnAfterConnected = () => void;
      const itemChangeListeners: FnItemChangesHandler[] = [];
      const taskChangeListeners: FnTaskChangesHandler[] = [];
      const progressiveTaskChangeListeners: FnTaskChangesHandler[] = [];
      const workShiftChangeListeners: FnWorkShiftChangesHandler[] = [];
      const alertChangeListeners: FnAlertChangesHandler[] = [];
      const afterConnectedListeners: FnAfterConnected[] = [];

      const getRefreshToken = async ({
        throwOnFailure = false,
        retryOnFailure = true,
        retryState,
      }: {
        throwOnFailure: boolean;
        retryOnFailure: boolean;
        retryState?: RetryState;
      }): Promise<RefreshTokenResponse | Error> => {
        let error: Error | null = null;
        try {
          setConnectionState(BackendState.Refreshing);
          const r = await fnRefreshToken({ refreshToken: rt });
          if (r instanceof Error) {
            error = r;
          } else {
            return r;
          }
        } catch (e) {
          error = e instanceof Error ? e : Error(JSON.stringify(e));
          setConnectionState(BackendState.AuthenticationError);
        }
        if (throwOnFailure) throw error;
        if (!retryOnFailure) return Error("Failed to fetch refresh token");
        if (!retryState) throw Error("Missing retrystate");
        setConnectionState(BackendState.AuthenticationError);
        const newRetryState = getNextRetryState(retryState);
        const newRetryStateWait = newRetryState.currentInterval;
        await delayAsync(newRetryStateWait);
        return await getRefreshToken({
          retryOnFailure,
          throwOnFailure,
          retryState: newRetryState,
        });
      };

      const getNextRefreshTimeout = ({ expiresIn }: { expiresIn: number }) => Math.floor((expiresIn * 1000) / 2);
      const updateRefreshToken = async () => {
        clearTimeout(nextRefreshTimeout);
        const r = await getRefreshToken({
          retryOnFailure: true,
          throwOnFailure: false,
          retryState: {
            currentInterval: 0,
            nextRetry: 0,
            retryIntervalIncrease: 1000,
            retryIntervalMax: 30000,
            retryIntervalMin: 1000,
          },
        });
        if (r instanceof Error) throw r;
        const { expiresIn, refreshToken: newRefreshToken, accessToken: newAccessToken } = r;
        at = newAccessToken;
        rt = newRefreshToken;
        // Schedule next refresh.
        const nextRefreshMs = getNextRefreshTimeout({ expiresIn });
        nextRefreshTimeout = setTimeout(updateRefreshToken, nextRefreshMs);
      };

      type FnOnSocketMessage = (x: WebSocketServerMessage) => void;
      const createPersistentSocket = ({
        url,
        logger,
        onAfterConnected,
        setConnectionState,
      }: {
        url: string;
        logger: Logger;
        onAfterConnected: () => void;
        setConnectionState: FnSetConnectionState;
      }) => {
        let ws: WebSocket | null = null;
        let isOpen = false;
        let isFailed = false;
        let defaultRetryState: RetryState = {
          currentInterval: 0,
          nextRetry: 1000,
          retryIntervalIncrease: 1000,
          retryIntervalMin: 1000,
          retryIntervalMax: 30000,
        };
        let retryState = defaultRetryState;
        let timeout: any = null;
        let messageHandlers: FnOnSocketMessage[] = [];

        const createWebSocket = ({
          onOpen,
          onError,
          onClose,
          onMessage,
        }: {
          onOpen: (e: Event) => void;
          onError: (e: Event) => void;
          onClose: (e: Event) => void;
          onMessage: (e: MessageEvent<any>) => void;
        }) => {
          const ws = new WebSocket(url);
          ws.addEventListener("open", onOpen);
          ws.addEventListener("close", onClose);
          ws.addEventListener("error", onError);
          ws.addEventListener("message", onMessage);
          return ws;
        };

        const onMessage = (e: MessageEvent<any>) => {
          const data = e.data;
          try {
            const parsedData = JSON.parse(data);
            messageHandlers.forEach((mh) => setTimeout(mh.bind(null, parsedData), 0));
          } catch (e) {
            logger?.error(`Failed to deserialized message '${data}'`);
          }
        };
        const onOpen = () => {
          logger?.log("Socket open");
          isOpen = true;
          isFailed = false;
          retryState = { ...defaultRetryState };
          setConnectionState(BackendState.Connected);
          onAfterConnected?.();
        };
        const onClose = ({ reason }: any) => {
          setConnectionState(BackendState.Closed);
          logger?.warn("Socket closed", reason);
          isOpen = false;
          isFailed = true;
          recreate();
        };
        const onError = (e: any) => {
          setConnectionState(BackendState.SocketError);
          logger?.error("Socker error", e?.message);
          isFailed = true;
        };

        const recreate = () => {
          if (timeout) clearTimeout(timeout);
          const oldRetryState = retryState;
          const newRetryState = getNextRetryState(retryState);
          const ts = newRetryState.currentInterval;
          retryState = newRetryState;
          logger?.log(`Retrying connection in ${ts}`, oldRetryState, newRetryState);
          timeout = setTimeout(() => {
            ws = createWebSocket({
              onMessage,
              onClose,
              onError,
              onOpen,
            });
          }, ts);
        };

        const sendMessage = (x: any) => {
          if (!isOpen) throw Error("Not connected");
          const str = typeof x === "string" ? x : JSON.stringify(x);
          ws?.send(str);
        };

        const waitUntilOpen = () => {
          return new Promise<void>((res, rej) => {
            const check = () => {
              if (isOpen) {
                return res();
              }
              if (isFailed) {
                return rej();
              }
              setTimeout(check, 100);
            };
            check();
          });
        };

        const addMessageHandler = (cb: FnOnSocketMessage) => {
          messageHandlers.push(cb);
        };

        const init = () => {
          if (ws) return;
          ws = createWebSocket({
            onMessage,
            onClose,
            onError,
            onOpen,
          });
        };

        const api = {
          init,
          sendMessage,
          addMessageHandler,
          waitUntilOpen,
        };
        return { ...api };
      };

      type SocketHandler = ReturnType<typeof createPersistentSocket>;
      type SocketResponseHandler = (x: WebSocketResponse) => void;

      const createRequestHandler = ({ socket, timeout }: { socket: SocketHandler; timeout: number }) => {
        const handlers: Record<string, SocketResponseHandler> = {};

        const isResponseToRequest = (x: WebSocketServerMessage): x is WebSocketResponse =>
          "requestId" in x && !isNil(x["requestId"]);

        // Redirect response messages to waiting handlers.
        // WS is not request-response protocol so matching has to be done "manually".
        const messageHandler: FnOnSocketMessage = (data) => {
          if (isResponseToRequest(data)) {
            const requestId = data.requestId!;
            const cb = handlers[requestId];
            if (cb) {
              // Pass response to waiting handler.
              cb(data);
              delete handlers[requestId];
            }
          }
        };
        // Send request and wait for response with matching 'requestId' or timeout.
        const sendAndWaitResponse = async <TRequest extends WebSocketRequestMessage>({
          action,
          payload,
          requestId,
        }: {
          action: MessageAction;
          payload: TRequest;
          requestId: string;
        }) => {
          let resolved = false;
          let response: WebSocketResponseMessage | null = null;
          let failed = false;
          let failedMessage = null;
          const body: WebSocketRequest = {
            action,
            payload,
            requestId,
          };
          const isWebSocketResponseSuccess = (x: WebSocketResponse): x is WebSocketResponseSuccess => x.success;
          const handler: SocketResponseHandler = (x: WebSocketResponse) => {
            resolved = true;
            if (isWebSocketResponseSuccess(x)) {
              response = x.payload;
            } else {
              failed = true;
              failedMessage = x.message;
            }
          };
          handlers[requestId] = handler;
          socket.sendMessage(body);

          await delayUntil({
            condition: () => resolved,
            timeout,
            interval: 16,
          });

          if (!resolved) throw Error(`Timeout for request ${requestId}`);
          // if (!response) throw Error(`No response for request ${requestId}`);
          if (failed) throw Error(`Request failed ${requestId}: ${failedMessage}`);
          return response;
        };

        // Listen to socket messages.
        socket.addMessageHandler(messageHandler);

        return {
          sendAndWaitResponse,
        };
      };

      let nextRefreshMs = getNextRefreshTimeout({ expiresIn: Math.floor((expTs - Date.now()) / 1000) });
      // Refresh token if it's expired or expiring soon.
      if (expTs < Date.now() - 60_000 * 5) {
        const r = await getRefreshToken({
          retryOnFailure: false,
          throwOnFailure: false,
        });
        if (r instanceof Error) {
          onLogOut();
          throw r;
        }
        at = r.accessToken;
        rt = r.refreshToken;
        nextRefreshMs = getNextRefreshTimeout({ expiresIn: r.expiresIn });
      }
      // Schedule later updates before token expires.
      setTimeout(updateRefreshToken, nextRefreshMs);

      const url = env.REACT_APP_WORKER_URL;
      if (!url) throw new Error("WORKER_URL NOT SPECIFIED IN ENV");
      const socket = createPersistentSocket({
        logger, url,
        setConnectionState,
        onAfterConnected: () => {
          // Authenticate after connection made.
          api
            .authenticate()
            .then(() => {
              logger?.log("Socket authenticated");
              setConnectionState(BackendState.ConnectedAndAuthenticated);
              afterConnectedListeners.forEach((acl) => setTimeout(acl, 0));
            })
            .catch((e) => {
              logger?.log("Socket failed to authenticated", e);
              setConnectionState(BackendState.AuthenticationError);
            });
        },
      });

      // Create request handler which sends requests through socket and calls callbacks.
      const requestHandler = createRequestHandler({
        socket,
        timeout: 60_000, // Request timeout.
      });

      // Send request message with unique ID and wait for response.
      const sendMsg = async <TRequest extends WebSocketRequestMessage, TResponse extends WebSocketResponseMessage>(
        action: MessageAction,
        payload: TRequest,
      ) => {
        const id = fnRandomIdentifier();
        return (await requestHandler.sendAndWaitResponse<TRequest>({
          action,
          requestId: id,
          payload,
        })) as TResponse;
      };

      socket.addMessageHandler((x) => {
        const action = x.action;
        if (action === MessageAction.ItemChanges) {
          const args = (x as any)["payload"];
          itemChangeListeners.forEach((icl) => setTimeout(icl.bind(null, args)), 0);
        }
        if (action === MessageAction.TaskChanges) {
          const args = (x as any)["payload"];
          const module = (args as TaskChangesResponse).module;
          switch (module) {
            case ModuleType.SmartToolManagement:
              taskChangeListeners.forEach((tcl) => setTimeout(tcl.bind(null, args)), 0);
              break;
            case ModuleType.ProgressiveAssemblyManagement:
              progressiveTaskChangeListeners.forEach((tcl) => setTimeout(tcl.bind(null, args)), 0);
              break;
          }

        }
        if (action === MessageAction.AlertChanges) {
          const args = (x as any)["payload"];
          alertChangeListeners.forEach((acl) => setTimeout(acl.bind(null, args)), 0);
        }
        if (action === MessageAction.WorkShiftChanges) {
          const args = (x as any)["payload"];
          workShiftChangeListeners.forEach((wcl) => setTimeout(wcl.bind(null, args)), 0);
        }
        if (action === MessageAction.Ping) {
          logger?.log("Ping");
          const requestId = (x as any).requestId;
          socket.sendMessage({
            action: "Pong",
            requestId,
          });
        }
      });

      const init = async () => {
        socket.init();
        await socket.waitUntilOpen();
        logger?.log("Socket connected");
      };

      // Backend public API.
      const api = {
        init,
        authenticate: async () =>
          sendMsg<AuthenticateRequest, EmptyResponse>(MessageAction.Authenticate, {
            accessToken: at,
          }),
        addAfterConnectedHandler: (fn: FnAfterConnected) => afterConnectedListeners.push(fn),

        //Modules
        getModules: async () => sendMsg<EmptyRequest, GetModulesResponse>(MessageAction.GetModules, null),

        //WorkShifts
        getWorkshifts: async (req: GetWorkShiftsRequest) => sendMsg<GetWorkShiftsRequest, GetWorkShiftsResponse>(MessageAction.GetWorkShifts, req),
        subscribeWorkShiftChanges: async (req: RegisterToWorkShiftChangesRequest) => sendMsg<RegisterToWorkShiftChangesRequest, EmptyResponse>(MessageAction.RegisterToWorkShiftChanges, req),
        addWorkShiftChangesHandler: (fn: FnWorkShiftChangesHandler) => workShiftChangeListeners.push(fn),

        // Layout.
        getLayout: async () => sendMsg<EmptyRequest, GetLayoutResponse>(MessageAction.GetLayout, null),
        getLayoutImage: async ({ id }: { id: number }) =>
          sendMsg<GetLayoutImageRequest, GetLayoutImageResponse>(MessageAction.GetLayoutImage, { id }),

        // Items.
        getItems: async () => sendMsg<EmptyRequest, GetItemsResponse>(MessageAction.GetItems, null),
        createItem: async (req: CreateItemRequest) => sendMsg<CreateItemRequest, EmptyResponse>(MessageAction.CreateItem, req),
        editItem: async (req: EditItemRequest) => sendMsg<EditItemRequest, EmptyResponse>(MessageAction.EditItem, req),
        removeItem: async (req: RemoveItemRequest) => sendMsg<RemoveItemRequest, EmptyResponse>(MessageAction.RemoveItem, req),
        setItemLed: async (req: SetItemLedRequest) => sendMsg<SetItemLedRequest, EmptyResponse>(MessageAction.SetItemLed, req),
        subscribeItemChanges: async () => sendMsg<EmptyRequest, EmptyResponse>(MessageAction.RegisterToItemChanges, null),
        addItemChangesHandler: (fn: FnItemChangesHandler) => itemChangeListeners.push(fn),

        // Alerts.
        getAlerts: async () => sendMsg<EmptyRequest, GetAlertsResponse>(MessageAction.GetAlerts, null),
        subscribeToAlertChanges: async () => sendMsg<EmptyRequest, EmptyResponse>(MessageAction.RegisterToAlertChanges, null),
        addAlertChangesHandler: (fn: FnAlertChangesHandler) => alertChangeListeners.push(fn),

        // Tasks.
        getTasks: async (req: GetTasksRequest) => sendMsg<GetTasksRequest, GetTasksResponse>(MessageAction.GetTasks, req),
        createTask: async (req: SmartToolManagementCreateTaskRequest) => sendMsg<SmartToolManagementCreateTaskRequest, EmptyResponse>(MessageAction.CreateTask, req),
        editTask: async (req: SmartToolManagementEditTaskRequest) => sendMsg<SmartToolManagementEditTaskRequest, EmptyResponse>(MessageAction.EditTask, req),
        removeTask: async (req: RemoveTaskRequest) => sendMsg<RemoveTaskRequest, EmptyResponse>(MessageAction.RemoveTask, req),
        updateTask: async (req: SmartToolManagementUpdateTaskRequest) => sendMsg<SmartToolManagementUpdateTaskRequest, EmptyResponse>(MessageAction.UpdateTask, req),
        subscribeTaskChanges: async (req: RegisterToTaskChangesRequest) => sendMsg<RegisterToTaskChangesRequest, EmptyResponse>(MessageAction.RegisterToTaskChanges, req),
        addTaskChangesHandler: (fn: FnTaskChangesHandler) => taskChangeListeners.push(fn),
        addProgressiveTaskChangesHandler: (fn: FnTaskChangesHandler) => progressiveTaskChangeListeners.push(fn),

      };

      return {
        ...api,
      };
    };
import { ModuleType, SmartToolManagementSubtaskType, SmartToolManagementTaskType } from "@noccela/dna-iot-shared";
import { useState } from "react";
import { MdArrowRightAlt, MdLogin, MdOutlineMoreVert } from "react-icons/md";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { calcWidth } from "../functions";
import { useTranslations } from "../hooks";
import { itemAtom } from "../state/items";
import { areaAtom } from "../state/map";
import { errorMessageAtom } from "../state/misc";
import { ServiceContainer, SmartToolManagementTask } from "../types";
import OutsideAlerter from "./OutsideAlerter";

type Content = {
  task: SmartToolManagementTask,
  targetStart: Date | null,
  type: SmartToolManagementSubtaskType,
  color: string,
  areaId: number,
  id: number
};
export const KanbanCard = ({ content, props, services }: { content: Content; props: any; services: ServiceContainer }) => {

  const color = content.color;
  const { amountProduced, amountToProduce, materialCode, materialAmount } = content.task;
  const subType = content.type;
  const targetStart = content.targetStart;
  const targetArea = useRecoilValue(areaAtom(content.areaId));
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const setError = useSetRecoilState(errorMessageAtom);
  const item = useRecoilValue(itemAtom(content.task.itemId));

  const t = useTranslations();

  const deleteTask = async () => {
    const id = content?.task.id;
    try {
      if (!id) throw new Error("No task ID");
      const res = await services.api?.removeTask({
        id,
        module: ModuleType.SmartToolManagement
      });
    } catch (error) {
      console.error(error);
      setError({
        message: t({
          id: "Error.DeletingTask",
        }),
        details: (error as any)?.message,
      });
    }
  };

  const setTaskToDone = async () => {
    try {
      if (!content?.task.id) throw new Error("No task ID");
      const params = {
        id: content?.task.id,
        module: ModuleType.SmartToolManagement,
        activeSubtask: content.task.subtasks[content.task.subtasks.length - 1].type,
        subtaskCompleted: true
      };
      if (content.task.type === SmartToolManagementTaskType.Production) {
        const params2 = {
          ...params,
          amountProduced: content?.task.amountProduced || 0,
        };
        const res = await services.api?.updateTask(params2);
      } else {
        const res = await services.api?.updateTask(params as any);
      }
    } catch (error) {
      console.error(error);
      setError({
        message: t({
          id: "Error.SettingTaskToDone",
        }),
        details: (error as any)?.message,
      });
    }
  };

  let title = item?.name ?? "-";
  if (subType == SmartToolManagementSubtaskType.MoveMaterialToLocation) title = `${materialCode} x ${materialAmount}`
  const subtitle = targetArea?.name;
  let tar = targetStart ?? new Date();
  return (
    <div className="flex flex-col w-full px-4 pt-4 pb-4 bg-white drop-shadow mb-2">
      <div className="flex w-full">
        <MdLogin className={`text-4xl mr-2 ${color ? "text-" + color : "text-dna-kuumapinkki"}`} />
        <div className="flex flex-col">
          <h1 className="text-dna-textcolor font-bold text-ellipsis">{title}</h1>
          {subtitle && (
            <h2 className="text-dna-textcolor font-bold text-ellipsis flex flex-row items-center gap-x-3">
              <MdArrowRightAlt />
              {subtitle}
            </h2>
          )}
          <p className="text-sm">
            {tar.toLocaleDateString()} {t({ id: "Time.Abbreviation" })}{" "}
            {tar.toLocaleTimeString().substring(0, 5)}
          </p>
        </div>
        <button
          className="relative mb-auto text-2xl text-dna-textcolor hover:bg-dna-vaaleanharmaa rounded"
          onClick={() => setShowDropDown(!showDropDown)}
        >
          <MdOutlineMoreVert />
        </button>
        {showDropDown && (
          <OutsideAlerter
            className="absolute z-10 w-auto flex right-4 drop-shadow top-10"
            onClickOutside={() => setShowDropDown(false)}
          >
            <div className="flex flex-col">
              <button onClick={() => setTaskToDone()} className="hover:bg-dna-vaaleanharmaa px-4 py-2 bg-white">
                {t({ id: "Actions.SetDone" })}
              </button>
              <button onClick={() => deleteTask()} className="hover:bg-dna-vaaleanharmaa px-4 py-2 bg-white">
                {t({ id: "Actions.DeleteTask" })}
              </button>
            </div>
          </OutsideAlerter>
        )}
      </div>
      <div className="h-1 w-full bg-dna-backgroundgray">
        <div
          className={`h-full ${color ? "bg-" + color : "bg-dna-kuumapinkki"}`}
          style={{ width: calcWidth(amountToProduce || 0, amountProduced || 0) + "%" }}
        />
      </div>
    </div>
  );
};

export default KanbanCard;

import { ModuleType } from "@noccela/dna-iot-shared";
import React from "react";
import { Header } from "../components/Header";
import Logo from "../components/Logo";
import Main from "../components/Main";
import { DefaultNav } from "../components/Nav";
import { Translations } from "../hooks";
import type { Pages } from "../types";

type FnOnChangePage = (page: Pages) => void;
type FnOnChangeModule = (module: ModuleType) => void;

type Props = {
  children: React.ReactNode;
  onChangePage: FnOnChangePage;
  pageTitle: string;
  translations: Translations;
  onModuleChange: FnOnChangeModule;
};

export const Scaffold = ({ children, pageTitle, onChangePage, onModuleChange, translations }: Props) => {
  const logo = <Logo />;
  const title = <>{pageTitle}</>;
  const nav = <DefaultNav onChangePage={onChangePage} />;
  return (
    <div className="flex flex-col w-full h-full">
      <Header logo={logo} title={title} nav={nav} translations={translations} onModuleChanged={onModuleChange} />
      <Main>{children}</Main>
    </div>
  );
};

export default Scaffold;

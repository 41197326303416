import { useEffect, useState } from "react";
import { RecoilRoot, useRecoilValue, useSetRecoilState } from "recoil";
import { useTranslations } from "./hooks";
import { useBackendIntegration } from "./state/hook";
import { connectionStateAtom, currentPageAtom, isDetailsOpenAtom, currentModuleAtom } from "./state/misc";
import { COLORS, Pages, ServiceContainer } from "./types";
import DashboardView from "./views/DashView";
import { DebugPage } from "./views/DebugView";
import KanbanView from "./views/KanbanView";
import ProgressiveKanbanView from "./views/ProgressiveKanbanView";
import ListView from "./views/ListView";
import { MapView } from "./views/MapView";
import Scaffold from "./views/Scaffold";
import { IntlProvider } from "react-intl";
import { StatefulErrorMessage } from "./components/Dialog";
import { MdLogout } from "react-icons/md";
import { ModuleType } from "@noccela/dna-iot-shared";
import { ErrorBoundary, FallbackProps } from 'react-error-boundary'
import env from "react-dotenv";


function ErrorHandler({ error, resetErrorBoundary }: FallbackProps) {
  const [seconds, setSeconds] = useState(15);

  useEffect(() => {
    const newSeconds = seconds - 1;
    setTimeout(() => {
      setSeconds(newSeconds);
    }, 1000);
  }, [seconds]);

  if (seconds === 0) {
    resetErrorBoundary();
    return null;
  }
  return (
    <div role="alert">
      <p>Reloading in: {seconds} seconds</p>
    </div>
  )
}

const ConnectionInfo = () => {
  const state = useRecoilValue(connectionStateAtom);
  return <div className="w-auto fixed bottom-2 left-2 font-bold font-mono text-dna-textcolor">CS: {state}</div>;
};

const SignoutButton = ({ onLogout }: { onLogout: () => void }) => {
  const t = useTranslations();
  return (
    <button className="fixed bottom-1 right-1 w-auto flex items-center gap-x-1 hover:cursor-pointer" onClick={onLogout}>
      <MdLogout /> {t({ id: "Signout" })}
    </button>
  );
};

const InnerApp = () => {

  const currentModule = useRecoilValue(currentModuleAtom);
  const setCurrentModule = useSetRecoilState(currentModuleAtom);

  const currentPage = useRecoilValue(currentPageAtom);
  const setCurrentPage = useSetRecoilState(currentPageAtom);

  const setIsDetailsOpen = useSetRecoilState(isDetailsOpenAtom);
  const t = useTranslations();
  const loginUrl = env.REACT_APP_LOGIN_URL;

  const onLogout = () => {
    if (!loginUrl) throw new Error("LOGIN_URL NOT SPEFICIED IN ENV");
    window.localStorage.clear();
    window.location.assign(loginUrl + "#logout=true");
  };

  const handlePageChange = (page: Pages) => {
    setCurrentPage(page);
    setIsDetailsOpen(false);
  };

  const handleModuleChange = (module: ModuleType) => {
    setCurrentModule(module);
    if (module == ModuleType.ProgressiveAssemblyManagement) {
      setCurrentPage(Pages.Kanban);
    }
    setIsDetailsOpen(false);
  };


  // Global service container, passes cross-cutting services down the tree.
  const services: ServiceContainer = {
    logger: {
      error: (...args) => console.error(...args),
      log: (...args) => console.log(...args),
      warn: (...args) => console.warn(...args),
    },
    api: null,
    constants: {
      dev: process.env.NODE_ENV !== "production",
    },
    t,
    colors: COLORS,
  };

  // API adapter.
  const api = useBackendIntegration({ services }, onLogout);

  services["api"] = api;
  const isAuthenticated = !!api;

  // Render current page.
  const content = (() => {
    if (!isAuthenticated) {
      //onLogout();
      return {
        content: <>{t({ id: "UnknownPage" })}</>,
        title: t({ id: "UnknownPage" }),
      };
    }
    if (currentModule == ModuleType.ProgressiveAssemblyManagement) {
      return { content: <ProgressiveKanbanView translations={t} />, title: t({ id: "KanbanPageTitle" }) };
    }
    switch (currentPage) {
      case Pages.Empty:
        return services.constants.dev
          ? {
            content: <DebugPage services={services} />,
            title: t({ id: "DebugPageTitle" }),
          }
          : {
            content: <></>,
            title: t({ id: "AppTitle" }),
          };
      case Pages.Kanban:
        return { content: <KanbanView services={services} />, title: t({ id: "KanbanPageTitle" }) };
      case Pages.List:
        return { content: <ListView services={services} />, title: t({ id: "ListPageTitle" }) };
      case Pages.Map:
        return { content: <MapView services={services} />, title: t({ id: "MapPageTitle" }) };
      case Pages.Dashboard:
        return {
          content: <DashboardView />,
          title: t({ id: "DashboardPageTitle" }),
        };
      default:
        return {
          content: <>{t({ id: "UnknownPage" })}</>,
          title: t({ id: "UnknownPage" }),
        };
    }
  })();

  return (
    <>
      <IntlProvider locale="fi" defaultLocale="fi">
        <Scaffold pageTitle={content.title} onChangePage={handlePageChange} onModuleChange={handleModuleChange} translations={t}>
          {content.content}
          <SignoutButton onLogout={onLogout} />
        </Scaffold>
        {services.constants.dev && <ConnectionInfo />}
        <StatefulErrorMessage services={services} />
      </IntlProvider>
    </>
  );
};

export const App = () => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorHandler}
      onReset={() => {
        window.location.reload();
      }}
    >
      <RecoilRoot>
        <InnerApp />
      </RecoilRoot>
    </ErrorBoundary>
  );
};

export default App;

import { useRecoilValue, useSetRecoilState } from "recoil";
import { useTranslations } from "../hooks";
import { currentItemAtom, currentItemIdAtom } from "../state/items";
import { isDetailsOpenAtom, isEditOpenAtom } from "../state/misc";
import { ServiceProps } from "../types";
import Button from "./Button";
import { StatefulSingleTagMapView } from "./map/Map";
import TagDetailCard from "./TagDetailCard";

export const TagDetails = ({ services }: ServiceProps) => {
  const t = useTranslations();
  const selectedItem = useRecoilValue(currentItemAtom);
  const setItemId = useSetRecoilState(currentItemIdAtom);
  const setIsDetailsOpen = useSetRecoilState(isDetailsOpenAtom);

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex pb-4">
        <h1 className="text-3xl font-bold">{t({ id: "Details.Tag" })}</h1>
        <Button
          text={t({ id: "Actions.Return" }).toUpperCase()}
          variant="primary"
          className="ml-auto p-1 hover:bg-dna flex items-center"
          onClick={() => {
            setItemId(null);
            setIsDetailsOpen(false);
          }}
        />
      </div>
      {selectedItem ? <TagDetailCard item={selectedItem} services={services} /> : <h2>{t({ id: "TaskState.Unknown" })}</h2>}
      {selectedItem && selectedItem?.location && <StatefulSingleTagMapView item={selectedItem} className="flex-1 p-10" />}
    </div>
  );
};

export default TagDetails;

import Board, { moveCard } from "@asseinfo/react-kanban";
import { SmartToolManagementTaskType, SmartToolManagementSubtaskType, ModuleType } from "@noccela/dna-iot-shared";
import { useEffect, useState } from "react";
import "../styles/kanban.css";
import { ServiceProps, SmartToolManagementTask, SmartToolManagementSubTask } from "../types";
import KanbanCard from "./KanbanCard";
import KanbanColumn from "./KanbanColumn";

type Props = ServiceProps & { tasks: SmartToolManagementTask[] };
const Kanban = ({ tasks, services }: Props) => {
  const { t } = services;

  const initialBoard = {
    columns: [
      {
        id: 1,
        title: t({ id: "TaskState.MoveToWork" }),
        color: "bg-dna-kuumapinkki",
        cards: [],
      },
      {
        id: 2,
        title: t({ id: "TaskState.Waiting" }),
        color: "bg-dna-kivenharmaa",
        cards: [],
      },
      {
        id: 3,
        title: t({ id: "TaskState.Active" }),
        color: "bg-dna-kuumapinkki",
        cards: [],
      },
      {
        id: 4,
        title: t({ id: "TaskState.MoveToWarehouse" }),
        color: "bg-dna-vedensininen",
        cards: [],
      },
      {
        id: 5,
        title: t({ id: "TaskState.MoveToMaintenance" }),
        color: "bg-dna-vedensininen",
        cards: [],
      },
      {
        id: 6,
        title: t({ id: "TaskState.InMaintenance" }),
        color: "bg-dna-vedensininen",
        cards: [],
      },
    ],
  };
  const [board, setBoard] = useState(initialBoard);

  useEffect(() => {
    let activeSubtasks = [];
    let id = 0;
    for (const task of tasks) {
      let active: SmartToolManagementSubTask[] = task.subtasks.filter(a => a.start != null && a.stop == null);
      if (active.length == 0) active.push(task.subtasks[0]);
      for (const t of active) {
        activeSubtasks.push({
          task: task,
          type: t.type,
          targetStart: t.targetStart,
          areaId: t.areaId,
          id: id
        });
        id++;
      }
    }
    const newBoard = {
      columns: [
        {
          id: 1,
          title: t({ id: "TaskState.MoveToWork" }),
          color: "bg-dna-kuumapinkki",
          cards: activeSubtasks
            .filter((n) => n.task.type === SmartToolManagementTaskType.Production)
            .filter((m) => m.type === SmartToolManagementSubtaskType.MoveObjectToLocation || m.type === SmartToolManagementSubtaskType.MoveMaterialToLocation)
            .map((n) => ({ ...n, color: "dna-kuumapinkki" })) as any,
        },
        {
          id: 2,
          title: t({ id: "TaskState.Waiting" }),
          color: "bg-dna-kivenharmaa",
          cards: activeSubtasks
            .filter((n) => n.task.type === SmartToolManagementTaskType.Production)
            .filter((m) => m.type === SmartToolManagementSubtaskType.WaitingAtLocation)
            .map((n) => ({ ...n, color: "dna-kivenharmaa" })) as any,
        },
        {
          id: 3,
          title: t({ id: "TaskState.Active" }),
          color: "bg-dna-kuumapinkki",
          cards: activeSubtasks
            .filter((n) => n.task.type === SmartToolManagementTaskType.Production)
            .filter((m) => m.type === SmartToolManagementSubtaskType.InTheWorks)
            .map((n) => ({ ...n, color: "dna-kuumapinkki" })) as any,
        },
        {
          id: 4,
          title: t({ id: "TaskState.MoveToWarehouse" }),
          color: "bg-dna-vedensininen",
          cards: activeSubtasks
            .filter((m) => m.type === SmartToolManagementSubtaskType.MoveFromLocation)
            .map((n) => ({ ...n, color: "dna-vedensininen" })) as any,
        },
        {
          id: 5,
          title: t({ id: "TaskState.MoveToMaintenance" }),
          color: "bg-dna-vedensininen",
          cards: activeSubtasks
            .filter((n) => n.task.type === SmartToolManagementTaskType.Maintenance)
            .filter((m) => m.type === SmartToolManagementSubtaskType.MoveObjectToLocation)
            .map((n) => ({ ...n, color: "dna-vedensininen" })),
        },
        {
          id: 6,
          title: t({ id: "TaskState.InMaintenance" }),
          color: "bg-dna-vedensininen",
          cards: activeSubtasks
            .filter((n) => n.task.type === SmartToolManagementTaskType.Maintenance)
            .filter((m) => m.type === SmartToolManagementSubtaskType.InTheWorks)
            .map((n) => ({ ...n, color: "dna-vedensininen" })) as any,
        },
      ],
    };
    setBoard(newBoard);
  }, [tasks]);

  const stateArr = [
    {
      id: 1,
      state: SmartToolManagementSubtaskType.MoveObjectToLocation,
      type: SmartToolManagementTaskType.Production,
    },
    {
      id: 2,
      state: SmartToolManagementSubtaskType.WaitingAtLocation,
      type: SmartToolManagementTaskType.Production,
    },
    {
      id: 3,
      state: SmartToolManagementSubtaskType.InTheWorks,
      type: SmartToolManagementTaskType.Production,
    },
    {
      id: 4,
      state: SmartToolManagementSubtaskType.MoveFromLocation,
      type: SmartToolManagementTaskType.Maintenance || SmartToolManagementTaskType.Production,
    },
    {
      id: 5,
      state: SmartToolManagementSubtaskType.MoveObjectToLocation,
      type: SmartToolManagementTaskType.Maintenance,
    },
    {
      id: 6,
      state: SmartToolManagementSubtaskType.InTheWorks,
      type: SmartToolManagementTaskType.Maintenance,
    },
  ];

  const updateTask = async (id: number, state: SmartToolManagementSubtaskType, type: SmartToolManagementTaskType, amountProduced: number | null) => {
    try {
      const params = {
        id: id,
        module: ModuleType.SmartToolManagement,
        activeSubtask: state,
        subtaskCompleted: false
      };
      if (type === SmartToolManagementTaskType.Production) {
        const params2 = {
          ...params,
          amountProduced: amountProduced || 0,
        };
        const res = await services.api?.updateTask(params2);
      } else {
        const res = await services.api?.updateTask(params as any);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleMoveCard = (_card: any, source: any, destination: any) => {
    const updatedBoard = moveCard(board, source, destination);
    const stateToPlace = stateArr.find((n) => n.id === destination.toColumnId);

    // TODO: Card restrictions disabled temporarily.
    // if (_card.type !== stateToPlace?.type) return;
    if (stateToPlace) {
      updateTask(_card.task.id, stateToPlace.state, _card.task.type, _card.task.amountProduced);
    }
    setBoard(updatedBoard);
  };

  return (
    <div className="w-full flex -mx-2">
      <Board
        onCardDragEnd={handleMoveCard}
        disableColumnDrag
        renderColumnHeader={({ title, ...props }: { title: any }) => (
          <KanbanColumn title={title} props={props as any} key={title} />
        )}
        renderCard={(card: any, ...cardBag: any) => (
          <KanbanCard content={card} props={cardBag} key={card.id} services={services} />
        )}
      >
        {board}
      </Board>
    </div>
  );
};

export default Kanban;

import clsx from "clsx";
import { useTranslations } from "../hooks";
import styles from "./ListHead.module.css";

export const ListHead = () => {
  const t = useTranslations();
  return (
    <thead className={clsx("w-full py-4 pr-8 pl-4 font-bold text-left border-b border-dna-vaaleanharmaa", styles.td)}>
      <tr className="w-full flex">
        <td className="flex">{t({ id: "Heading.Name" })}</td>
        <td className="w-4/6">{t({ id: "Heading.Type" })}</td>
        <td className="w-5/6">{t({ id: "Item.Location" })}</td>
        <td>{t({ id: "Heading.State" })}</td>
        <td>{t({ id: "Heading.Timestamp" })}</td>
        <td>{t({ id: "Heading.TargetTimestamp" })}</td>
        <td className="w-4/12" />
        <td className="w-10/12">{t({ id: "Heading.Condition" })}</td>
        <td className="w-10/12">{t({ id: "Heading.Battery" })}</td>
      </tr>
    </thead>
  );
};

export default ListHead;

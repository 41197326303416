import clsx from "clsx";
import React from "react";
import { MdAir, MdLogin, MdPhone, MdPlace, MdStarOutline, MdViewColumn } from "react-icons/md";
import { useRecoilValue } from "recoil";
import { useTranslations } from "../hooks";
import { currentPageAtom } from "../state/misc";
import { currentModuleAtom } from "../state/misc";
import { Pages } from "../types";
import styles from "./Nav.module.css";
import { ReactComponent as ListIcon } from "../icon_list.svg";
import { ReactComponent as KanbanIcon } from "../icon_kanban.svg";
import { ReactComponent as MapIcon } from "../icon_map.svg";
import { ReactComponent as DashboardIcon } from "../icon_dashboard.svg";
import { noop } from "lodash";
import { ModuleType } from "@noccela/dna-iot-shared";

type FnOnClick = (e: React.MouseEvent<HTMLElement>) => void;
type Props = {
  onClick: FnOnClick;
  title: string;
  active?: boolean;
  icon: React.ReactElement;
  disabled?: boolean;
};
export const NavButton = ({ onClick, title, active, icon, disabled }: Props) => {
  return (
    <div className="flex flex-col">
      <button
        onClick={onClick}
        className={clsx("flex gap-x-2 items-center px-3 rounded", disabled && "hover:cursor-not-allowed")}
      >
        <span className={clsx(disabled ? "text-dna-kivenharmaa" : "text-dna-kuumapinkki", styles.navicon)}>
          {icon && React.cloneElement(icon, { className: "w-full h-full" })}
        </span>
        <span
          className={clsx(
            "text-lg font-bold text-dna-textcolor",
            active && styles.active,
            !disabled && styles.customUnderline,
            !disabled && styles.customUnderlineKuumapinkki,
          )}
        >
          {title}
        </span>
      </button>
    </div>
  );
};

export const Nav = ({
  buttons,
}: {
  buttons: {
    page: Pages;
    icon: React.ReactElement;
    title: string;
    active?: boolean;
    onClick?: () => void;
    disabled?: boolean;
  }[];
}) => {
  return (
    <div className="flex flex-row gap-x-4 justify-between items-center w-full h-full">
      {buttons.map((b) => (
        <NavButton
          key={b.page}
          onClick={b.disabled ? noop : () => b.onClick?.()}
          title={b.title}
          active={b.active}
          icon={b.icon}
          disabled={b.disabled}
        />
      ))}
    </div>
  );
};

type FnOnChangePage = (page: Pages) => void;

export const DefaultNav = ({ onChangePage }: { onChangePage: FnOnChangePage }) => {
  const t = useTranslations();
  const currentPage = useRecoilValue(currentPageAtom);
  const currentModule = useRecoilValue(currentModuleAtom);

  let buttons = [];
  switch (currentModule) {
    case ModuleType.SmartToolManagement:
      buttons = [
        {
          page: Pages.List,
          icon: <ListIcon />,
          title: t({ id: "Page.Orders" }),
          onClick: () => onChangePage(Pages.List),
          active: currentPage === Pages.List,
        },
        {
          page: Pages.Map,
          icon: <MapIcon />,
          title: t({ id: "Page.Map" }),
          onClick: () => onChangePage(Pages.Map),
          active: currentPage === Pages.Map,
        },
        {
          page: Pages.Kanban,
          icon: <KanbanIcon />,
          title: t({ id: "Page.Worklist" }),
          onClick: () => onChangePage(Pages.Kanban),
          active: currentPage === Pages.Kanban,
        },
        // Dash page is disabled temporarily.
        // {
        //   page: Pages.Dashboard,
        //   icon: <DashboardIcon />,
        //   title: t({ id: "Page.Indicators" }),
        //   onClick: () => onChangePage(Pages.Dashboard),
        //   active: currentPage === Pages.Dashboard,
        //   disabled: true,
        // },
      ];
      break;
    default:
      buttons = [
        {
          page: Pages.Kanban,
          icon: <KanbanIcon />,
          title: t({ id: "Page.Worklist" }),
          onClick: () => onChangePage(Pages.Kanban),
          active: currentPage === Pages.Kanban,
        }
      ];
      break;
  }

  return <Nav buttons={buttons} />;
};

export const TestNav = () => {
  return (
    <Nav
      buttons={[
        {
          icon: <MdPhone />,
          page: Pages.Empty,
          title: "First",
          active: true,
        },
        {
          icon: <MdPhone />,
          page: Pages.Empty,
          title: "Second",
        },
        {
          icon: <MdPhone />,
          page: Pages.Empty,
          title: "Third",
        },
      ]}
    />
  );
};

import { atom, selector, selectorFamily } from "recoil";
import { Area, AreaKey, Blueprint, Floor, FloorKey } from "../types";

export const floorsAtom = atom<Record<FloorKey, Floor>>({
  key: "floors",
  default: {},
});
export const floorsListAtom = selector<Floor[]>({
  key: "floors_list",
  get: ({ get }) => {
    const floors = get(floorsAtom);
    return Object.values(floors).sort((f) => f.id);
  },
});
export const areasAtom = atom<Record<AreaKey, Area>>({
  key: "areas",
  default: {},
});
export const areaAtom = selectorFamily<Area | null, AreaKey>({
  key: "areas",
  get:
    (key: AreaKey) =>
    ({ get }) => {
      const areas = get(areasAtom);
      return areas[key] || null;
    },
});
export const areasListAtom = selector<Area[]>({
  key: "areas_list",
  get: ({ get }) => {
    const areas = get(areasAtom);
    return Object.values(areas).sort((a) => a.id);
  },
});
export const blueprintAtom = atom<Blueprint | null>({
  key: "blueprint",
  default: null,
});

export const KanbanColumn = ({ title, props }: { title: string; props: any }) => {
  return (
    <div
      className={`h-auto text-lg font-bold text-center px-6 whitespace-normal items-center flex text-white justify-center ${
        props.color ? props.color : "bg-dna-kuumapinkki"
      }`}
      style={{ minHeight: "3.5rem" }}
    >
      {title.toUpperCase()}
    </div>
  );
};

export default KanbanColumn;

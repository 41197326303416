import { AreaType, SmartToolManagementSubtaskType, SmartToolManagementTaskType } from "@noccela/dna-iot-shared";
import clsx from "clsx";
import { default as distinctColors } from "distinct-colors";
import { useEffect, useState } from "react";
import { Group, Image as KonvaImage, Layer, Rect as KonvaRect, Stage, Text } from "react-konva";
import { useRecoilValue } from "recoil";
import { useDeepCompareMemoize } from "../../hooks";
import { areasListAtom, blueprintAtom } from "../../state/map";
import { Area, Blueprint, Item, ItemWithTask } from "../../types";
import { AutoResizer } from "../Autoresizer";
import { AnimatedMapItem } from "./MapItem";

type Props = {
  blueprint: Blueprint | null;
  areas: Area[];
  items: Item[];
  itemColor?: string;
  itemRadius?: number;
};

const isItemWithTask = (item: Item): item is ItemWithTask => "task" in item;

const _globalColorStorage: Record<string, string> = {};
const _globalColors = (() => {
  const dc = distinctColors({
    count: 100,
  });
  const hex = dc.map((d: any) => d.hex());
  return hex;
})();

export function getDictinctColor(id: string) {
  const oldColor = _globalColorStorage[id];
  if (oldColor) return oldColor;
  const i = Math.floor(Math.random() * _globalColors.length);
  const newColor = _globalColors[i];
  _globalColorStorage[id] = newColor;
  return newColor;
}

export const Map = ({ blueprint, areas, items, itemRadius, itemColor }: Props) => {
  const [image, setImage] = useState<HTMLImageElement>();
  const bpArea = areas?.find((a) => a.type === AreaType.Image);
  const drawCanvas = true;

  const bp1 = !!blueprint?.content;
  const bp2 = useDeepCompareMemoize(bpArea);

  useEffect(() => {
    if (!blueprint) return;
    if (!blueprint.content) return;
    if (!blueprint.contentType) return;
    const image = new Image();
    image.src = `data:${blueprint.contentType};base64,${blueprint.content}`;
    image.addEventListener("load", () => {
      setImage(image);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bp1, bp2]);

  if (!bpArea) return null;
  if (!bpArea.width) return null;
  if (!bpArea.height) return null;
  if (!image) return null;

  type FnGetCoordinates = ReturnType<typeof getRelativeCoordinates>;
  const getRelativeCoordinates =
    ({
      layoutX,
      layoutY,
      layoutW,
      layoutH,
      containerW,
      containerH,
    }: {
      layoutX: number;
      layoutY: number;
      layoutW: number;
      layoutH: number;
      containerW: number;
      containerH: number;
    }) =>
      ({
        x,
        y,
        width,
        height,
        debug,
      }: {
        x: number;
        y: number;
        width?: number;
        height?: number;
        debug?: boolean;
      }): { x: number; y: number; width: number | null; height: number | null } | null => {
        const layoutWidthHalf = layoutW / 2;
        const layoutHeightHalf = layoutH / 2;
        const projectedX = x + layoutWidthHalf - layoutX;
        const projectedY = layoutHeightHalf - y + layoutY; // Y coordinate is inverted due to Y axis pointing up.
        const layoutProjectedX = projectedX / layoutW;
        const layoutProjectedY = projectedY / layoutH;
        const layoutProjectedW = width ? width / layoutW : null;
        const layoutProjectedH = height ? height / layoutH : null;
        const containerProjectedX = layoutProjectedX * containerW;
        const containerProjectedY = layoutProjectedY * containerH;
        const containerProjectedW = layoutProjectedW ? layoutProjectedW * containerW : null;
        const containerProjectedH = layoutProjectedH ? layoutProjectedH * containerH : null;
        return {
          x: containerProjectedX,
          y: containerProjectedY,
          width: containerProjectedW,
          height: containerProjectedH,
        };
      };

  const getAreaRenderer =
    ({ getCoordinates }: { getCoordinates: FnGetCoordinates }) =>
      ({ id, name, width, height, x, y }: Area) => {
        if (!x || !y || !width || !height) return null;
        const coordinates = getCoordinates({
          x,
          y,
          width,
          height,
          // debug: name === "Meconet tehdas",
        });
        if (!coordinates) return null;
        const { x: newX, y: newY, width: newW, height: newH } = coordinates;
        if (!newW || !newH) return null;
        const newWidthHalf = newW / 2;
        const newHeightHalf = newH / 2;
        const newX2 = newX - newWidthHalf;
        const newY2 = newY - newHeightHalf;
        const color = getDictinctColor(`area-${id}`);
        return (
          <Group>
            <KonvaRect x={newX2} y={newY2} width={newW} height={newH} fill={color} opacity={0.2} />
            <KonvaRect
              x={newX2}
              y={newY2}
              width={newW}
              height={newH}
              stroke={color}
              strokeWidth={5}
              // fill={color}
              opacity={0.8}
            />
            <Text text={name} x={newX2} y={newY2} />
          </Group>
        );
      };

  const getItemRenderer =
    ({ getCoordinates, r }: { getCoordinates: FnGetCoordinates; r: number }) =>
      (item: Item) => {
        let taskType: SmartToolManagementTaskType = SmartToolManagementTaskType.Unknown;
        let subtaskType: SmartToolManagementSubtaskType = SmartToolManagementSubtaskType.Unknown;
        const { name, location, id, type } = item;
        if (isItemWithTask(item)) {
          let active = item?.task?.subtasks.find(a => a.start != null && a.stop == null);
          subtaskType = active?.type || SmartToolManagementSubtaskType.Unknown;
          taskType = item.task?.type || SmartToolManagementTaskType.Unknown;
        }
        if (!location) return null;
        const coordinates = getCoordinates({
          x: location.x,
          y: location.y,
        });
        if (!coordinates) return null;
        const { x: newX, y: newY } = coordinates;
        return (
          <AnimatedMapItem
            id={id}
            name={name}
            r={itemRadius || r}
            x={newX}
            y={newY}
            type={type}
            taskType={taskType}
            subtaskType={subtaskType}
            key={id}
            color={itemColor || null}
          />
        );
      };

  return (
    <>
      {drawCanvas && (
        <AutoResizer
          className="h-full flex justify-center items-center"
          callback={({ width, height }) => {
            // const bpRatio = image.width / image.height;
            const bpRatio = bpArea.width! / bpArea.height!;
            const containerWidth = Math.min(height * bpRatio, width);
            const containerHeight = height;
            const smallerDimension = Math.min(containerWidth, containerHeight);
            const getCoordinates = getRelativeCoordinates({
              containerW: containerWidth,
              containerH: containerHeight,
              layoutW: bpArea.width!,
              layoutH: bpArea.height!,
              layoutX: bpArea.x,
              layoutY: bpArea.y,
            });
            const itemRadius = smallerDimension / 100;
            const itemRenderer = getItemRenderer({
              getCoordinates,
              r: itemRadius,
            });
            const areaRenderer = getAreaRenderer({
              getCoordinates,
            });
            const itemElements = items.map(itemRenderer);
            const areaElements = areas.filter((a) => a.type !== AreaType.Image).map(areaRenderer);
            return (
              <div style={{ width: `${containerWidth}px`, height: `${containerHeight}px` }}>
                <Stage width={containerWidth} height={containerHeight}>
                  <Layer>
                    {image && <KonvaImage image={image} x={0} y={0} width={containerWidth} height={containerHeight} />}
                    {areaElements}
                    {itemElements}
                  </Layer>
                </Stage>
              </div>
            );
          }}
        />
      )}
    </>
  );
};

export const StatefulSingleTagMapView = ({ item, className }: { item: Item; className: string }) => {
  const areas = useRecoilValue(areasListAtom);
  const blueprint = useRecoilValue(blueprintAtom);
  if (!blueprint) return null;
  return (
    <>
      <section className={clsx("flex flex-col justify-between items-center", className)}>
        <Map items={[item]} blueprint={blueprint} areas={areas} />
      </section>
    </>
  );
};

import { useRecoilValue } from "recoil";
import Button from "../components/Button";
import { filteredItemsListAtom, itemFilterAtom, itemsAtom, itemsListAtom, itemSorterAtom } from "../state/items";
import { areasAtom, blueprintAtom, floorsAtom } from "../state/map";
import { filteredTasksListAtom, tasksAtom, tasksFilterAtom, tasksListAtom } from "../state/tasks";
import { ServiceProps } from "../types";

export function DebugPage({ services }: ServiceProps) {
	const itemSort = useRecoilValue(itemSorterAtom);
	const itemFilter = useRecoilValue(itemFilterAtom);
	const itemsDict = useRecoilValue(itemsAtom);
	const items = useRecoilValue(itemsListAtom);
	const itemsFiltered = useRecoilValue(filteredItemsListAtom);
	const taskFilter = useRecoilValue(tasksFilterAtom);
	const taskDict = useRecoilValue(tasksAtom);
	const tasks = useRecoilValue(tasksListAtom);
	const tasksFiltered = useRecoilValue(filteredTasksListAtom);
	const blueprint = useRecoilValue(blueprintAtom);
	const areas = useRecoilValue(areasAtom);
	const floors = useRecoilValue(floorsAtom);

	const renderSection = ({ title, data }: any) => {
		return (
			<section className="w-full bg-red-300 mb-3 overflow-x-auto">
				<h1>{title}</h1>
				<code className="w-full h-20">{JSON.stringify(data)}</code>
			</section>
		);
	};

	const handleClick = () => { };

	return (
		<>
			<section className="mb-3 flex flex-row gap-x-5">
				<Button onClick={handleClick} variant="primary" text="Klik" />
				<Button onClick={handleClick} variant="primary" text="Klik" />
			</section>
			{[
				{ title: "Floors", data: floors },
				{ title: "Areas", data: areas },
				{ title: "ItemsSorter", data: itemSort },
				{ title: "ItemsFilter", data: itemFilter },
				{ title: "Items", data: itemsDict },
				{ title: "ItemsList", data: items },
				{ title: "ItemsListFiltered", data: itemsFiltered },
				{ title: "TasksFilter", data: taskFilter },
				{ title: "Tasks", data: taskDict },
				{ title: "TasksList", data: tasks },
				{ title: "TasksListFiltered", data: tasksFiltered },
			].map((args) => renderSection(args))}
			<section>
				<img width={100} src={`data:${blueprint?.contentType};base64,${blueprint?.content}`} alt="" />
			</section>
		</>
	);
}

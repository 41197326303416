import clsx from "clsx";
import { memo } from "react";
import { BatteryStatus, ItemType } from "@noccela/dna-iot-shared";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { useTranslations } from "../hooks";
import { Item, ServiceProps } from "../types";
import styles from "./AddObject.module.css";
import Button from "./Button";
import { Input } from "./Input";
import { errorMessageAtom } from "../state/misc";
import { useSetRecoilState } from "recoil";

export const AddObject = memo(
  ({ setIsModalOpen, services }: { setIsModalOpen: Dispatch<SetStateAction<boolean>> } & ServiceProps) => {
    const t = useTranslations();
    const setError = useSetRecoilState(errorMessageAtom);

    const [newItem, setNewItem] = useState<Item>({
      batteryStatus: BatteryStatus.Ok,
      id: 0,
      type: ItemType.Forklift,
      name: "",
      description: "",
      tagSerialNumber: null,
      strokeMaintenanceInterval: null,
      strokesSinceMaintenance: 0,
      location: null,
      lastActivity: null,
    });
    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>, type: string) => {
      if (e.target.checked) {
        if (type === "Forklift") {
          const item = {
            ...newItem,
            type: ItemType.Forklift,
          };
          setNewItem(item);
        } else if (type === "Tool") {
          const item = {
            ...newItem,
            type: ItemType.Tool,
          };
          setNewItem(item);
        }
      } else {
        const item = {
          ...newItem,
          type: ItemType.Unknown,
        };
        setNewItem(item);
      }
    };

    const onChangeHandler = (value: any, fieldName: string) => {
      const item = {
        ...newItem,
        [fieldName]: value,
      };

      setNewItem(item);
    };

    const addNewObject = async () => {
      try {
        const params = {
          type: newItem.type,
          name: newItem.name,
          description: newItem.description,
          tagSerialNumber: Number(newItem.tagSerialNumber) || null,
          strokeMaintenanceInterval: newItem.strokeMaintenanceInterval ? Number(newItem.strokeMaintenanceInterval) : null,
        };
        if (Number.isNaN(params.strokeMaintenanceInterval)) throw Error("Invalid stroke maintenance interval");
        const res = await services.api?.createItem(params);
      } catch (error) {
        console.error(error);
        setError({
          message: t({
            id: "Error.AddingObject",
          }),
          details: (error as any)?.message,
        });
      } finally {
        setIsModalOpen(false);
      }
    };

    const isItemForklift = newItem?.type === ItemType.Forklift;
    const isItemTool = newItem?.type === ItemType.Tool;
    // Optional.
    const isStrokeMaintenanceIntervalOk = !newItem.strokeMaintenanceInterval || newItem.strokeMaintenanceInterval > 0;
    // Required.
    const isNameOk = Boolean(newItem.name);
    // Optional.
    const isSerialNumberOk = !newItem.tagSerialNumber || newItem.tagSerialNumber > 0;
    // Required.
    const isTypeOk = newItem.type && newItem.type !== ItemType.Unknown;
    const isFormValid =
      (newItem.type !== ItemType.Tool || isStrokeMaintenanceIntervalOk) && isNameOk && isSerialNumberOk && isTypeOk;

    return (
      <div className={clsx("bg-dna-puhdasvalkoinen flex flex-col relative m-auto p-4 z-20 drop-shadow", styles.container)}>
        <div className="flex">
          <h1 className="text-lg font-semibold">{t({ id: "Modal.AddObject" })}</h1>
          <button className="hover:bg-dna-vaaleanharmaa p-1 rounded ml-auto text-2xl" onClick={() => setIsModalOpen(false)}>
            <MdOutlineClose />
          </button>
        </div>
        <div className="flex items-center mt-2">
          <label className="flex items-center">
            <Input
              type="radio"
              className="mr-2 w-auto"
              checked={newItem.type === ItemType.Forklift}
              onChange={(e) => handleCheckboxChange(e, "Forklift")}
            />
            {t({ id: "Type.ForkLift" })}
          </label>
          <label className="flex items-center">
            <Input
              type="radio"
              className="mr-2 ml-4 w-auto"
              checked={newItem.type === ItemType.Tool}
              onChange={(e) => handleCheckboxChange(e, "Tool")}
            />
            {t({ id: "Type.Tool" })}
          </label>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex flex-col space-y-2">
            <div>
              <label>{t({ id: "Heading.Name" })}</label>
              <Input
                type="text"
                value={newItem.name}
                onChange={(e) => onChangeHandler(e.target.value, "name")}
                error={!isNameOk}
                showInitialError={true}
              />
            </div>
            <div>
              <label>{t({ id: "Modal.TagSerialNumber" })}</label>
              <Input
                type="number"
                value={newItem?.tagSerialNumber || ""}
                onChange={(e) => {
                  let value: number | null = parseInt(e.target.value);
                  if (isNaN(value)) value = null;
                  onChangeHandler(value, "tagSerialNumber");
                }}
                error={!isSerialNumberOk}
              />
            </div>
            <div>
              <label>{t({ id: "Modal.MaintenanceInterval" })}</label>
              <Input
                type="text"
                disabled={!isItemTool}
                value={isItemTool ? newItem?.strokeMaintenanceInterval || "" : ""}
                onChange={(e) => onChangeHandler(e.target.value, "strokeMaintenanceInterval")}
                error={isItemTool && !isStrokeMaintenanceIntervalOk}
                showInitialError
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label>{t({ id: "Modal.DescriptionText" })}</label>
            <textarea
              className="p-2 resize-none"
              value={newItem?.description || ""}
              onChange={(e) => onChangeHandler(e.target.value, "description")}
            />
          </div>
        </div>
        <div className="flex mt-4">
          <Button
            variant="secondary"
            text={t({ id: "Actions.Cancel" }).toUpperCase()}
            onClick={() => {
              setIsModalOpen(false);
            }}
          />
          <Button
            variant="primary"
            text={t({ id: "Actions.Add" }).toUpperCase()}
            className="ml-auto"
            disabled={!isFormValid}
            onClick={() => {
              addNewObject();
            }}
          />
        </div>
      </div>
    );
  },
);

export default AddObject;

import { AreaType, ModuleType, SmartToolManagementTaskType } from "@noccela/dna-iot-shared";
import clsx from "clsx";
import { ChangeEvent, Dispatch, memo, SetStateAction, useEffect, useRef, useState } from "react";
import { MdOutlineClose } from "react-icons/md";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useItemsWithTasks, useTranslations } from "../hooks";
import { currentItemIdAtom, itemAtom, linkedTaskAtom } from "../state/items";
import { areasAtom } from "../state/map";
import { errorMessageAtom } from "../state/misc";
import { Item, ServiceProps, SmartToolManagementTask } from "../types";
import Button from "./Button";
import styles from "./EditContent.module.css";
import { Input } from "./Input";
import FixedSelect from "./Select";

export const EditContent = memo(
	({
		isNew,
		canModifyTask,
		setIsModalOpen,
		services,
	}: {
		isNew: boolean;
		setIsModalOpen: Dispatch<SetStateAction<boolean>>;
		canModifyTask: boolean;
	} & ServiceProps) => {
		const t = useTranslations();
		const itemId = useRecoilValue(currentItemIdAtom);
		const setItemId = useSetRecoilState(currentItemIdAtom);
		const currentItem = useRecoilValue(itemAtom(itemId));
		const currentTask = useRecoilValue(linkedTaskAtom(itemId as number));
		const itemsWithTasks = useItemsWithTasks();
		const setError = useSetRecoilState(errorMessageAtom);
		const [item, setItem] = useState<Partial<Item>>({});
		const [task, setTask] = useState<SmartToolManagementTask>({
			id: 0,
			type: SmartToolManagementTaskType.Maintenance,
			subtasks: [],
			amountToProduce: null,
			amountProduced: null,
			materialCode: null,
			materialAmount: null,
			priority: 1,
			module: ModuleType.SmartToolManagement,
			itemId: null,
			targetTimestamp: new Date(),
			completed: false,
			areaId: 0,
			erpId: null
		});
		const items = Object.fromEntries(itemsWithTasks.map((i) => [i.id, i]));
		const areas = useRecoilValue(areasAtom);

		const initTask = useRef<boolean>(true);
		const initItem = useRef<boolean>(true);

		useEffect(() => {
			if (isNew) {
				return;
			}
			if (currentTask && initTask.current) {
				setTask(currentTask);
				initTask.current = false;
			}
			if (currentItem && initItem.current) {
				setItem(currentItem);
				initItem.current = false;
			}
		}, [currentTask, currentItem, isNew]);

		const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>, type: string) => {
			if (e.target.checked) {
				if (type === "Production") {
					const newTask = {
						...task,
						type: SmartToolManagementTaskType.Production,
					};
					setTask(newTask as SmartToolManagementTask);
				} else if (type === "Maintenance") {
					const newTask = {
						...task,
						type: SmartToolManagementTaskType.Maintenance,
					};
					setTask(newTask as SmartToolManagementTask);
				}
			} else {
				const newTask = {
					...task,
					type: SmartToolManagementTaskType.Unknown,
				};
				setTask(newTask as SmartToolManagementTask);
			}
		};

		const onToolChange = (value: number) => {
			const newTask = {
				...task,
				itemId: value,
			};
			setTask(newTask as SmartToolManagementTask);
		};

		const onAreaChange = (value: number) => {
			const newTask = {
				...task,
				areaId: value,
			};
			setTask(newTask as SmartToolManagementTask);
		};

		const onChangeHandler = (value: any, fieldName: string) => {
			const newTask = {
				...task,
				[fieldName]: value,
			};
			setTask(newTask as SmartToolManagementTask);
		};

		const onDateTimeChange = (value: string) => {
			try {
				const date = new Date(value + `Z`);
				const newTask = {
					...task,
					targetTimestamp: date,
				};
				setTask(newTask as SmartToolManagementTask);
			} catch {
				// Ignore
			}
		};

		const updateItem = async () => {
			try {
				if (!item.name || !item.id || !item.type) throw Error("Invalid item");
				const res = await services.api?.editItem({
					name: item.name,
					description: item.description || "",
					id: item.id,
					strokeMaintenanceInterval: item.strokeMaintenanceInterval,
					tagSerialNumber: item.tagSerialNumber,
					type: item.type,
				});
			} catch (e) {
				console.error(e);
				setError({
					message: t({
						id: "Error.UpdatingItem",
					}),
					details: (e as any)?.message,
				});
			} finally {
				setIsModalOpen(false);
			}
		};
		const createTask = async () => {
			try {

				const res = await services.api?.createTask({
					module: ModuleType.SmartToolManagement,
					type: task?.type || SmartToolManagementTaskType.Unknown,
					amountToProduce: Number(task.amountToProduce),
					materialCode: task.materialCode,
					materialAmount: task.materialAmount,
					targetTimestamp: task.targetTimestamp.toISOString(),
					priority: Number(task.priority),
					itemId: task.itemId as number,
					areaId: task.areaId
				});

			} catch (e) {
				console.error(e);
				setError({
					message: t({
						id: "Error.CreatingTask",
					}),
					details: (e as any)?.message,
				});
			} finally {
				setIsModalOpen(false);
			}
		};

		const updateTask = async () => {
			try {
				if (!task.id) throw new Error("Missing task id");
				const taskToUpdate = {
					module: ModuleType.SmartToolManagement,
					id: task.id,
					itemId: task.itemId as number,
					areaId: task.areaId,
					targetTimestamp: task.targetTimestamp.toISOString(),
					priority: Number(task.priority),
				};
				if (task?.type === SmartToolManagementTaskType.Maintenance) {
					const res = await services.api?.editTask(taskToUpdate as any);
				} else if (task?.type === SmartToolManagementTaskType.Production) {
					const productionTask = {
						...taskToUpdate,
						amountToProduce: Number(task.amountToProduce),
						materialCode: task.materialCode,
						materialAmount: task.materialAmount,
					};
					const res = await services.api?.editTask(productionTask);
				}
			} catch (error) {
				console.error(error);
				setError({
					message: t({
						id: "Error.UpdatingTask",
					}),
					details: JSON.stringify(error, null, 4),
				});
			} finally {
				setIsModalOpen(false);
			}
		};

		const now = new Date();
		const emptyOp = { value: null, label: "-" };
		const toolOps = [
			emptyOp,
			...Object.values(items)
				.filter((f) => !f.task || f.task.itemId == task.itemId)
				.map((n) => ({ value: n.id, label: n.name })),
		];
		const areaOps = [
			emptyOp,
			...Object.values(areas)
				.filter((a) => a.type !== AreaType.Image)
				.map((n) => ({ value: n.id, label: n.name })),
		];

		const currentToolOps = toolOps.find((n) => n.value === task?.itemId);
		const currentAreaOps = areaOps.find((n) => n.value === task?.areaId);
		const isToolNotFound = Boolean(task?.itemId && !currentToolOps);
		const isAreaNotFound = Boolean(task?.areaId && !currentAreaOps);
		const isProductionTask = task.type === SmartToolManagementTaskType.Production;
		const isMaintenanceTask = task.type === SmartToolManagementTaskType.Maintenance;
		const isAmountToProduceOk = Boolean(isProductionTask && task.amountToProduce && task.amountToProduce > 0);
		const isTargetTimestampValid = Boolean(!!task.targetTimestamp && task.targetTimestamp > now);
		const isPriorityValid = Boolean(task.priority && task.priority > 0);
		const isAreaIdValid = Boolean(task.areaId);
		const isItemIdValid = Boolean(task.itemId);
		const isTaskValid =
			canModifyTask &&
			Boolean(
				isTargetTimestampValid &&
				isPriorityValid &&
				((isMaintenanceTask && isItemIdValid && isAreaIdValid) ||
					(isProductionTask && isAreaIdValid) ||
					(isProductionTask && isAreaIdValid && isItemIdValid)),
			);
		const isItemValid = Boolean(item?.name) && (!item?.tagSerialNumber || item.tagSerialNumber > 0);

		const itemEditor = (
			<>
				<div className="flex">
					<h1 className="text-lg font-semibold">{isNew ? t({ id: "Modal.AddTask" }) : t({ id: "Modal.EditItem" })}</h1>
					<button className="hover:bg-dna-vaaleanharmaa p-1 rounded ml-auto text-2xl" onClick={() => setIsModalOpen(false)}>
						<MdOutlineClose />
					</button>
				</div>
				<div className="grid grid-cols-2 gap-4 my-2">
					<div className="flex flex-col row-start-1 col-start-1">
						<label>{t({ id: "Production.ItemName" })}</label>
						<Input
							type="text"
							className="px-2"
							value={item?.name ? item.name : ""}
							onChange={(e) => setItem((oldItem) => ({ ...oldItem, name: e.target.value }))}
						/>
					</div>
					<div className="flex flex-col">
						<label>{t({ id: "Production.ItemTagId" })}</label>
						<Input
							type="text"
							className="px-2"
							value={item?.tagSerialNumber ? item.tagSerialNumber : ""}
							onChange={(e) =>
								setItem((oldItem) => ({ ...oldItem, tagSerialNumber: e.target.value ? Number(e.target.value) : null }))
							}
						/>
					</div>
					<div className="flex flex-col">
						<label>{t({ id: "Details.Description" })}</label>
						<textarea
							className="p-2 resize-none"
							value={item?.description || ""}
							onChange={(e) => setItem((oldItem) => ({ ...oldItem, description: e.target.value }))}
						/>
					</div>
					<div className="flex justify-items-end row-start-2 col-start-2 relative">
						<Button
							variant="primary"
							text={t({ id: "Actions.Edit" }).toUpperCase()}
							className="ml-auto h-fit bottom-0 right-0 absolute"
							disabled={!isItemValid}
							onClick={updateItem}
						/>
					</div>
				</div>
			</>
		);

		const taskEditor = (
			<>
				<div className={clsx("flex", !isNew && "mt-4")}>
					<h1 className="text-lg font-semibold">{isNew ? t({ id: "Modal.AddTask" }) : t({ id: "Modal.EditTask" })}</h1>
					{isNew && (
						<button
							className="hover:bg-dna-vaaleanharmaa p-1 rounded ml-auto text-2xl"
							onClick={() => setIsModalOpen(false)}
						>
							<MdOutlineClose />
						</button>
					)}
				</div>
				{isNew && (
					<div className="flex items-center mt-2">
						<label className="flex items-center">
							<Input
								type="radio"
								className="mr-2 w-auto"
								checked={task?.type === "Maintenance"}
								onChange={(e) => handleCheckboxChange(e, "Maintenance")}
							/>
							{t({ id: "Type.Maintenance" })}
						</label>
						<label className="flex items-center">
							<Input
								type="radio"
								className="mr-2 ml-4 w-auto"
								checked={task?.type === "Production"}
								onChange={(e) => handleCheckboxChange(e, "Production")}
							/>
							{t({ id: "Type.Production" })}
						</label>
					</div>
				)}
				<div className="grid grid-cols-2 gap-4 my-2">
					<div className="flex flex-col">
						<label>{t({ id: "Production.Tool" })}</label>
						<div>
							<FixedSelect
								t={t}
								height={36}
								isDisabled={!isNew}
								options={toolOps}
								value={currentToolOps}
								onChange={(e: any) => {
									if (e) {
										onToolChange(e?.value);
									}
								}}
								isSearchable
								error={!isItemIdValid}
								showInitialError
							/>
							{isToolNotFound && <p className="text-red-600 text-bold h-0">{t({ id: "Error.ToolNotFound" })}</p>}
						</div>
					</div>
					<div className="flex flex-col">
						<label>{t({ id: "Production.NumberToProduce" })}</label>
						<Input
							type="number"
							className="px-2"
							value={isProductionTask ? (task?.amountToProduce ? task.amountToProduce : "") : ""}
							disabled={!isProductionTask}
							onChange={(e) => onChangeHandler(e.target.value, "amountToProduce")}
							error={!isAmountToProduceOk}
							showInitialError
						/>
					</div>
				</div>
				<div className="grid grid-cols-2 gap-4 mt-4">
					<div className="flex flex-col">
						<label>{t({ id: "Production.MaterialCode" })}</label>
						<Input
							type="text"
							className="w-full"
							disabled={task?.type !== SmartToolManagementTaskType.Production}
							value={isProductionTask ? (task?.materialCode ? task.materialCode : "") : ""}
							onChange={(e) => {
								let value: string | null = e.target.value;
								if (value.length == 0) value = null;
								onChangeHandler(value, "materialCode");
							}}
						/>
					</div>
					<div className="flex flex-col">
						<label>{t({ id: "Production.MaterialAmount" })}</label>
						<Input
							type="number"
							disabled={task?.type !== SmartToolManagementTaskType.Production}
							className="w-full px-2"
							value={isProductionTask ? (task?.materialAmount ? task.materialAmount : "") : ""}
							onChange={(e) => {
								let value: string | null = e.target.value;
								if (value.length == 0) value = null;
								onChangeHandler(value, "materialAmount");
							}}
						/>
					</div>
				</div>
				<div className="grid grid-cols-3 gap-4 mt-4">
					<div className="flex flex-col">
						<label>{t({ id: "Production.Target" })}</label>
						<FixedSelect
							t={t}
							height={36}
							isDisabled={!canModifyTask}
							options={areaOps}
							value={currentAreaOps}
							onChange={(e: any) => {
								if (e) {
									onAreaChange(e?.value);
								}
							}}
							error={!isAreaIdValid}
							showInitialError
						/>
						{isAreaNotFound && <p className="text-red-600 text-bold h-0">{t({ id: "Error.AreaNotFound" })}</p>}
					</div>
					<div className="flex flex-col">
						<label>{t({ id: "Task.TargetTimeStamp" })}</label>
						<Input
							disabled={!canModifyTask}
							type="datetime-local"
							min={now.toISOString().substring(0, 16)}
							className="p-1"
							value={task?.targetTimestamp ? task?.targetTimestamp.toISOString().substring(0, 16) : ""}
							onChange={(e) => onDateTimeChange(e.target.value)}
							error={
								!isTargetTimestampValid ? (task.targetTimestamp <= now ? t({ id: "Error.TimestampError1" }) : true) : false
							}
							showInitialError
						/>
					</div>
					<div className="flex flex-col">
						<label>{t({ id: "Task.Priority" })}</label>
						<Input
							disabled={!canModifyTask}
							type="number"
							className="p-1"
							value={task?.priority}
							onChange={(e) => onChangeHandler(e.target.value, "priority")}
							error={!isPriorityValid}
							showInitialError
						/>
					</div>
				</div>
				<div className="flex mt-4">
					<Button
						variant="secondary"
						text={t({ id: "Actions.Cancel" }).toUpperCase()}
						onClick={() => {
							setItemId(null);
							setIsModalOpen(false);
						}}
					/>
					<Button
						variant="primary"
						text={(isNew ? t({ id: "Actions.Add" }) : t({ id: "Actions.Edit" })).toUpperCase()}
						className="ml-auto"
						disabled={!isTaskValid}
						onClick={() => {
							if (isNew) {
								createTask();
							} else {
								updateTask();
							}
						}}
					/>
				</div>
			</>
		);

		return (
			<div
				className={clsx(
					"bg-dna-puhdasvalkoinen flex flex-col relative m-auto p-4 z-20 drop-shadow",
					canModifyTask ? styles.containerLarge : styles.containerSmall,
				)}
			>
				{!isNew && itemEditor}
				{canModifyTask && taskEditor}
			</div>
		);
	},
);

export default EditContent;

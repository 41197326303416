import { } from "@noccela/dna-iot-shared";
import { identity, isNil } from "lodash";
import { atom, selector, selectorFamily } from "recoil";
import { SmartToolManagementTask, TaskFilter, TaskKey, TaskSorter } from "../types";
import { sortByDateProp } from "../util/state";

export const tasksAtom = atom<Record<TaskKey, SmartToolManagementTask>>({
  key: "tasks",
  default: {},
});

export const taskSorter = atom<TaskSorter>({
  key: "tasks_sort",
  default: {
    id: "asc",
  },
});
export const tasksOrderAtom = selector<TaskKey[]>({
  key: "tasks_order",
  get: ({ get }) => {
    const tasks = get(tasksAtom);
    const sort = get(taskSorter);
    const sorted = Object.values(tasks).sort((a, b) => {
      if (sort.id) {
        if (sort.id === "asc") return a.id - b.id;
        if (sort.id === "desc") return b.id - a.id;
      }
      if (sort.targetTimestamp) {
        return sortByDateProp({ prop: "targetTimestamp", direction: sort.targetTimestamp })(a, b);
      }
      return a.id - b.id;
    });
    return sorted.map((s) => s.id);
  },
});
export const tasksListAtom = selector<SmartToolManagementTask[]>({
  key: "tasks_list",
  get: ({ get }) => {
    const tasks = get(tasksAtom);
    const tasksOrder = get(tasksOrderAtom);
    return tasksOrder.map((t) => tasks[t]).filter(identity);
  },
});
export const tasksFilterAtom = atom<TaskFilter>({
  key: "tasks_filter",
  default: {},
});
export const filteredTasksListAtom = selector<SmartToolManagementTask[]>({
  key: "tasks_list_filtered",
  get: ({ get }) => {
    const tasks = get(tasksListAtom);
    const filter = get(tasksFilterAtom);
    const result = tasks.filter((t) => {
      if (filter.areaId && t.areaId !== filter.areaId) return false;
      if (filter.type && t.type !== filter.type) return false;
      return true;
    });
    return result;
  },
});
export const taskAtom = selectorFamily<SmartToolManagementTask | null, TaskKey>({
  key: "task",
  get:
    (id) =>
      ({ get }) => {
        if (isNil(id)) return null;
        return get(tasksAtom)[id];
      },
});

import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import styles from "../styles/Autoresizer.module.css";

export type ResizerCallbackArguments = { width: number; height: number };
export function AutoResizer({
  callback,
  className,
}: {
  callback: (props: ResizerCallbackArguments & { containerClassName: string }) => React.ReactNode;
  className?: string;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState<ResizerCallbackArguments>({
    width: 0,
    height: 0,
  });
  if (typeof callback !== "function") throw Error("Invalid child");

  useEffect(() => {
    if (!window) return;
    const handleWindowResize = () => {
      const el = ref.current;
      if (!el) return;
      const { width, height } = el.getBoundingClientRect();
      setDimensions({ width, height });
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  return (
    <>
      <div className={clsx(styles.container, className)} ref={ref}>
        {callback({ ...dimensions, containerClassName: styles.container })}
      </div>
    </>
  );
}

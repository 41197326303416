import React, { useRef, useEffect } from "react";

type OutsideAlerterProps = React.HTMLAttributes<HTMLDivElement> & {
  onClickOutside?: (evt: any) => void;
  parentRef?: React.RefObject<HTMLElement>;
};

export const OutsideAlerter = ({ onClickOutside, parentRef, children, ...props }: OutsideAlerterProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target) && !parentRef?.current?.contains(event.target)) {
        if (event.offsetX > window.innerWidth - 20) {
          return;
        }
        if (onClickOutside) {
          onClickOutside(event);
        }
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClickOutside, parentRef]);

  return (
    <div ref={wrapperRef} {...props}>
      {children}
    </div>
  );
};

export default OutsideAlerter;

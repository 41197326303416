import React, { useState } from "react";
import Select from "react-select";
import { Translations } from "../hooks";

export const FixedSelect = ({
  t,
  tabIndex,
  height,
  error,
  showInitialError,
  onChange,
  wrapperClass = "",
  ...props
}: React.ComponentProps<Select> & {
  t: Translations | null;
  height: number;
  error?: string | boolean;
  showInitialError?: boolean;
  wrapperClass?: string;
}) => {
  const [hasChanged, setHasChanged] = useState(false);
  const [isFocused, setFocused] = useState(false);
  const canShowError = showInitialError || hasChanged;
  const isError = (typeof error === "boolean" && error === true) || (typeof error === "string" && error.length > 0);
  const errorString = isError ? (typeof error === "string" ? error : null) : null;
  const handleChange: typeof onChange = (...args) => {
    setHasChanged(true);
    onChange?.(...args);
  };

  const sHalfHeight = `-${12}px`; // Hacky fix, issue seems to be within react-select, hardcoded margin to fix.
  let { styles } = props;

  if (styles == null) {
    styles = {
      control: (style: any) => ({
        // none of react-select's styles are passed to <Control />
        ...style,
        height,
        borderRadius: 2,
        borderColor: isError ? "rgb(220 38 38)" : "#e0e1dd",
        borderWidth: isError ? "2px" : style.borderWidth,
        width: "auto",
        maxWidth: "400px"
      }),
      indicatorsContainer: (style: any) => ({
        ...style,
        width: "auto",
        height,
      }),
      input: (styles: any) => ({
        ...styles,
      }),
      valueContainer: (styles: any) => ({
        ...styles,
        height,
      }),
      placeholder: (styles: any) => ({
        ...styles,
        marginTop: isFocused ? styles.marginTop || null : sHalfHeight,
      }),
      singleValue: (styles: any, state: any) => ({
        ...styles,
        marginTop: isFocused ? styles.marginTop || null : sHalfHeight,
      }),
      menu: (styles: any) => ({
        ...styles,
        maxWidth: "400px"
      }),
    };
  }

  return (
    <div className={wrapperClass}>
      <Select
        placeholder={t != null ? t({ id: "UI.Select" }) : ""}
        styles={styles}
        {...props}
        onFocus={setFocused.bind(null, true)}
        onChange={handleChange}
      />
      {canShowError && errorString && <p className="h-0 leading-tight left-1 text-red-600">{errorString}</p>}
    </div>
  );
};

export default FixedSelect;

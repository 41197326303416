import clsx from "clsx";
import { BatteryStatus, ItemType, SmartToolManagementTaskType, SmartToolManagementSubtaskType, ModuleType, SmartToolManagementUpdateTaskRequest } from "@noccela/dna-iot-shared";
import { SmartToolManagementTask } from "../types";
import { getStateTranslation } from "../functions";
import { Dispatch, memo, SetStateAction, useRef, useState } from "react";
import {
  MdBattery20,
  MdBatteryAlert,
  MdBatteryFull,
  MdBatteryUnknown,
  MdDeviceUnknown,
  MdEdit,
  MdLogout,
  MdMoreVert,
  MdOutlineSettings,
} from "react-icons/md";
import { FormattedDate } from "react-intl";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Translations, useTranslations } from "../hooks";
import { currentItemIdAtom, itemAtom, linkedTaskAtom } from "../state/items";
import { areasAtom } from "../state/map";
import { errorMessageAtom, isDetailsOpenAtom } from "../state/misc";
import { ItemKey, ServiceContainer } from "../types";
import { ReactComponent as ForkLiftSvg } from "../forklift.svg";
import styles from "../styles/ItemListItem.module.css";
import OutsideAlerter from "./OutsideAlerter";
import { LastActivityTimestampFromDate } from "./LastSeenTime";
import { isEqual } from "lodash";

type ListItemProps = {
  isLoading: boolean;
  item: ItemKey;
  style: React.CSSProperties;
  itemProps: {
    setShowModal: Dispatch<SetStateAction<boolean>>;
    services: ServiceContainer;
    isSelected: (id: ItemKey) => boolean;
    selectItem: (id: ItemKey) => void;
  };
};

export const TestListItem = ({ isLoading, item, style }: ListItemProps) => {
  if (isLoading) return <>Loading</>;
  return <div style={style}>Item {(item as any)["i"]}</div>;
};

export const renderBattery = ({
  t,
  status,
  renderText,
}: {
  t: Translations;
  status: BatteryStatus;
  renderText: boolean;
}) => {
  const { text, textColor, icon }: { text: string; textColor: string; icon: React.ReactNode } = ((status) => {
    if (status === BatteryStatus.Ok) {
      return {
        text: t({ id: "Details.BatteryFull" }),
        textColor: "text-dna-musta",
        icon: <MdBatteryFull />,
      };
    } else if (status === BatteryStatus.Low) {
      return {
        text: t({ id: "Details.BatteryLow" }),
        textColor: "text-dna-musta",
        icon: <MdBattery20 />,
      };
    } else if (status === BatteryStatus.Empty) {
      return {
        text: t({ id: "Details.BatteryEmpty" }),
        textColor: "text-dna-musta",
        icon: <MdBatteryAlert />,
      };
    }
    return {
      text: t({ id: "Details.BatteryUnknown" }),
      textColor: "text-dna-kivenharmaa",
      icon: <MdBatteryUnknown />,
    };
  })(status);
  return (
    <div className="flex flex-row items-center" title={text}>
      {icon}
      {renderText && <span className={clsx(textColor, "text-xs")}>{text}</span>}
    </div>
  );
};

export const ItemListItem = memo(
  ({ isLoading, item: itemId, style, itemProps }: ListItemProps) => {
    const t = useTranslations();
    const item = useRecoilValue(itemAtom(itemId));
    const setItemId = useSetRecoilState(currentItemIdAtom);
    const isDetailsOpen = useSetRecoilState(isDetailsOpenAtom);
    const areas = useRecoilValue(areasAtom);
    const task = useRecoilValue(linkedTaskAtom(itemId));
    const setError = useSetRecoilState(errorMessageAtom);
    const [showDropdown, setShowDropdown] = useState<boolean>(false);

    const ref = useRef(null);

    if (isLoading) {
      return (
        <div
          style={{
            ...style,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* <LoaderSpinner size="medium" /> */}
          Loading
        </div>
      );
    }

    const renderIcon = (type?: ItemType) => {
      if (type === ItemType.Forklift) {
        return <ForkLiftSvg />;
      } else if (type === ItemType.Tool) {
        return <MdOutlineSettings className="text-dna-kuumapinkki text-2xl" />;
      } else {
        <MdDeviceUnknown className="text-dna-kuumapinkki text-2xl" />;
      }
    };

    const renderType = (type?: ItemType) => {
      if (type === ItemType.Forklift) {
        return t({ id: "Type.ForkLift" });
      } else if (type === ItemType.Tool) {
        return t({ id: "Type.Tool" });
      } else {
        return t({ id: "Type.Unknown" });
      }
    };

    const calcCondition = (strokesSinceMaintenance?: number, strokeMaintenanceInterval?: number | null) => {
      if (
        strokeMaintenanceInterval === 0 ||
        strokesSinceMaintenance === 0 ||
        !strokeMaintenanceInterval ||
        !strokesSinceMaintenance
      ) {
        return 0;
      } else {
        return 100 - (strokesSinceMaintenance * 100) / (strokeMaintenanceInterval || 0);
      }
    };

    const setTaskToDone = async () => {
      try {
        if (!task?.id) throw new Error("No task. Id not found");

        const isProductionTask = task.type === SmartToolManagementTaskType.Production;
        const params: SmartToolManagementUpdateTaskRequest = {
          id: task?.id,
          module: ModuleType.SmartToolManagement,
          subtaskCompleted: true,
          activeSubtask: task?.subtasks[task.subtasks.length - 1].type,
          amountProduced: isProductionTask ? (task?.amountProduced || 0) : null
        };
        const res = await itemProps.services.api?.updateTask(params);
      } catch (error) {
        console.error(error);
        setError({
          message: t({
            id: "Error.SettingTaskAsDone",
          }),
          details: (error as any)?.message,
        });
      } finally {
        setShowDropdown(false);
      }
    };

    return (


      <tr
        className={clsx(
          "flex font-bold w-full p-4 border-b border-dna-vaaleanharmaa h-16 hover:cursor-pointer hover:bg-dna-vaaleanharmaa",
          styles,
        )}
        style={style}
        onClick={(e) => {
          setItemId(() => (item?.id ? item.id : null));
          isDetailsOpen(true);
        }}
        data-item-id={itemId}
      >
        <td className="flex items-center">
          <input
            readOnly
            type="checkbox"
            disabled={!!task}
            className="w-6 h-6 p-2 mr-2 hover:cursor-pointer disabled:cursor-not-allowed"
            checked={!!(item?.id && itemProps.isSelected(item?.id))}
            onClick={(e) => {
              e.stopPropagation();
              if (item) itemProps.selectItem(item?.id);
            }}
          />
          {renderIcon(item?.type)}
          <p className="ml-2">{item?.name}</p>
        </td>
        <td className="flex items-center w-4/6">{renderType(item?.type)}</td>
        <td className="flex items-center w-5/6">
          {item?.location?.areas.map((n) => Object.values(areas).find((m) => m.id === n)?.name).join(", ")}
        </td>
        <td className="flex items-center">{getStateTranslation(task, t)}</td>
        <td className="flex items-center text-sm">
          <LastActivityTimestampFromDate t={t} timestamp={item?.lastActivity || null} />
        </td>
        <td className="flex items-center text-sm">
          {task?.targetTimestamp ? (
            <FormattedDate
              value={task?.targetTimestamp.toISOString().substring(0, 16) as any}
              day="numeric"
              month="numeric"
              year="numeric"
              hour="numeric"
              minute="numeric"
            />
          ) : (
            "-"
          )}
        </td>
        <td className="w-4/12 ">
          <button
            ref={ref}
            className="text-2xl p-1 rounded border border-white hover:border-dna-kuumapinkki disabled:opacity-25"
            onClick={(e) => {
              e.stopPropagation();
              setShowDropdown((prev) => !prev);
            }}
            disabled={!task?.id}
          >
            <MdMoreVert />
          </button>
          {showDropdown && (
            <OutsideAlerter
              parentRef={ref}
              className="absolute z-10 w-auto flex drop-shadow top-12"
              onClickOutside={() => setShowDropdown(false)}
            >
              <div className="flex flex-col">
                <button
                  className="hover:bg-dna-vaaleanharmaa px-4 py-2 bg-white"
                  onClick={(e) => {
                    e.stopPropagation();
                    setTaskToDone();
                  }}
                >
                  {t({ id: "Actions.SetDone" })}
                </button>
              </div>
            </OutsideAlerter>
          )}
        </td>
        <td className="items-center flex w-10/12">
          <div className="h-2 w-full bg-dna-vaaleanharmaa border-white border mr-4">
            <div
              className="bg-dna-kuumapinkki h-2"
              style={{ width: calcCondition(item?.strokesSinceMaintenance, item?.strokeMaintenanceInterval) + "%" }}
            />
          </div>
        </td>
        <td className="flex text-2xl w-10/12 text-dna-kuumapinkki items-center">
          {renderBattery({ t, status: item?.batteryStatus || BatteryStatus.Unknown, renderText: false })}
          <div className="items-center flex w-full justify-end space-x-1">
            <button
              className={clsx("border hover:border-dna-kuumapinkki border-white p-1 rounded disabled:opacity-50")}
              onClick={(e) => {
                e.stopPropagation();
                setItemId(() => (item?.id ? item.id : null));
                itemProps.setShowModal(true);
              }}
            >
              <MdEdit />
            </button>
            <button className="border hover:border-dna-kuumapinkki border-white p-1 rounded">
              <MdLogout />
            </button>
          </div>
        </td>
      </tr>
    );
  },
  (p, n) => {
    if (p.isLoading !== n.isLoading) return false;
    if (p.item !== n.item) return false;
    if (!isEqual(p.itemProps, n.itemProps)) return false;
    return true;
  },
);

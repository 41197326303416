import { ReactComponent as DnaLogo } from "../dna-logo.svg";

export const Logo = () => {
	return (
		<>
			<DnaLogo style={{ "height": "32px", "marginTop": "16px", "marginLeft": "16px" }} />
		</>
	);
};

export default Logo;

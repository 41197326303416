import { SmartToolManagementSubtaskType } from "@noccela/dna-iot-shared";
import clsx from "clsx";
import { identity, isEqual } from "lodash";
import { useState } from "react";
import { useRecoilValue } from "recoil";
import { Map } from "../components/map/Map";
import { MapFilter, MapFilters } from "../components/map/MapFilters";
import { useItemsWithTasks } from "../hooks";
import { areasListAtom, blueprintAtom } from "../state/map";
import styles from "../styles/MapView.module.css";
import { ItemWithTask, ServiceProps } from "../types";

export const MapView = ({ services }: ServiceProps) => {
  const items = useItemsWithTasks();
  const areas = useRecoilValue(areasListAtom);
  const blueprint = useRecoilValue(blueprintAtom);
  const [filters, setFilters] = useState<MapFilter[]>([]);

  const doesFilterMatch = (item: ItemWithTask) => (filter: MapFilter) => {
    const { task, type: itemType } = item;
    const filterItemTypes = (Array.isArray(filter.itemType) ? filter.itemType : [filter.itemType]).filter(identity);
    const filterTaskTypes = (Array.isArray(filter.taskType) ? filter.taskType : [filter.taskType]).filter(identity);
    const filterSubtaskTypes: (SmartToolManagementSubtaskType | undefined)[] = (Array.isArray(filter.subtaskType) ? filter.subtaskType : [filter.subtaskType]).filter(identity);
    if (filterItemTypes.length && !filterItemTypes.includes(itemType)) return false;
    if (filterTaskTypes.length && !filterTaskTypes.includes(task?.type)) return false;
    let active = task?.subtasks.filter(a => a.start != null && a.stop == null);
    if (filterSubtaskTypes.length && !filterSubtaskTypes.some(a => {
      return task?.subtasks.find(b => b.start != null && b.stop == null && b.type == a);
    })) return false;
    return true;
  };

  const setFilter = (filter: MapFilter) => {
    const hasFilter = filters.some((f) => isEqual(f, filter));
    if (hasFilter) {
      return setFilters((old) => old.filter((o) => !isEqual(o, filter)));
    }
    return setFilters((old) => [...old, filter]);
  };

  const filteredItems = items.filter((i) => (filters.length ? filters.some(doesFilterMatch(i)) : true));

  return (
    <>
      <section className={clsx("flex flex-col justify-between items-center gap-y-3", styles.container)}>
        <Map items={filteredItems} blueprint={blueprint} areas={areas} />
        <MapFilters filters={filters} setFilter={setFilter} services={services} />
      </section>
    </>
  );
};

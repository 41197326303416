import { Alert as OriginalAlert, AreaType, Item as OriginalItem, ModuleType, Task as OriginalTask, SmartToolManagementTask, SmartToolManagementSubtaskType, ProgressiveAssemblyManagementSubtaskConfiguration, ProgressiveAssemblyManagementConfiguration, Subtask, WorkShift } from "@noccela/dna-iot-shared";
import { Alert, AlertKey, Floor, Item, Logger, Pages, SmartToolManagementTask as UiTask, TaskKey } from "../types";
import { ProgressiveLimits, SocketApi } from "./types";

const parseDate = (dateStr: string) => new Date(Date.parse(dateStr)) || null;
const transformTask = (task: OriginalTask): UiTask => {
	let t = task as UiTask;
	let subtasks = task.subtasks.map(a => {
		return {
			...a,
			targetStart: a.targetStart != null ? parseDate(a.targetStart) : null,
			targetStop: a.targetStop != null ? parseDate(a.targetStop) : null,
			start: a.start != null ? parseDate(a.start) : null,
			stop: a.stop != null ? parseDate(a.stop) : null,
			reportedStart: a.reportedStart != null ? parseDate(a.reportedStart) : null,
			reportedStop: a.reportedStop != null ? parseDate(a.reportedStop) : null,
			type: a.type as SmartToolManagementSubtaskType
		}
	});
	let target = subtasks.find(a => a.targetStart != null)?.targetStart ?? new Date();
	return {
		...t,
		type: t.type,
		subtasks: subtasks,
		areaId: subtasks[0].areaId ?? 0,
		targetTimestamp: target
	};
};
const transformItem = (item: OriginalItem): Item => {
	return {
		...item,
		lastActivity: item.lastActivity ? parseDate(item.lastActivity) : null,
	};
};
const transformAlert = (item: OriginalAlert): Alert => {
	return {
		...item,
		timestamp: parseDate(item.timestamp),
	};
};

export type BackendApi = ReturnType<ReturnType<typeof createBackendStateHandler>>;

export const createBackendStateHandler =
	({ api, logger }: { api: SocketApi; logger: Logger }) =>
		({
			setAlerts,
			setTasks,
			setItems,
			setAreas,
			setFloors,
			setBlueprint,
			setModules,
			setPhases,
			setShifts,
			setProgressiveTasks,
			setCurrentModule,
			setCurrentPage,
			gate,
			setCurrentPhase,
			setLimits,
			throwError,
		}: {
			setAlerts: any;
			setTasks: any;
			setItems: any;
			setAreas: any;
			setFloors: any;
			setBlueprint: any;
			setModules: any;
			setPhases: any;
			setShifts: any;
			setProgressiveTasks: any;
			setCurrentModule: any;
			setCurrentPage: any;
			gate: number | null;
			setCurrentPhase: any;
			setLimits: any;
			throwError: any;
		}) => {

			const init = async () => {

				try {
					const modulesResponse = await api.getModules();
					const modules = modulesResponse.modules.map(a => a.type);
					const hasProgressive = modules.includes(ModuleType.ProgressiveAssemblyManagement);
					setModules(modules);
					if (modules.length > 0) {
						if (hasProgressive && gate != null) {
							setCurrentModule(ModuleType.ProgressiveAssemblyManagement);
							setCurrentPage(Pages.Kanban);
						}
						else {
							const firstModule = modules[0];
							setCurrentModule(firstModule);
							if (firstModule == ModuleType.ProgressiveAssemblyManagement) setCurrentPage(Pages.Kanban);
						}
					}

					if (hasProgressive) {
						const progressiveConf = modulesResponse.modules.find(a => a.type === ModuleType.ProgressiveAssemblyManagement);
						if (progressiveConf != null) {
							const conf = (progressiveConf.configuration as ProgressiveAssemblyManagementConfiguration);
							const limits: ProgressiveLimits = {
								upperControlLimit: conf.spcUcl,
								lowerControlLimit: conf.spcLcl,
								upperLimit: conf.spcUl,
								lowerLimit: conf.spcLl,
								keepDotsBetweenLlAndUl: conf.spcKeepDotsBetweenLlAndUl
							}
							setLimits(limits);
							const phases = conf.subtasks.filter(a => !a.buffer);
							setPhases(phases);

							if (phases && phases.length > 0) {
								if (gate && gate < phases.length) {
									setCurrentPhase(phases[gate]);
								} else {
									setCurrentPhase(phases[0]);
								}
							}

							const workShiftsResponse = await api.getWorkshifts({
								module: ModuleType.ProgressiveAssemblyManagement
							});
							setShifts(workShiftsResponse.workShifts);
							const t = await api.getTasks({
								module: ModuleType.ProgressiveAssemblyManagement
							});
							setProgressiveTasks(t.tasks);
						}
						api.addProgressiveTaskChangesHandler((task) => {

							const updatedTasks = task.tasks || [];
							setProgressiveTasks((oldTasks: OriginalTask[]) => {
								// Remove tasks.
								const t2 = oldTasks.filter(a => !task.tasksToRemove.includes(a.id));
								// Update tasks.
								const t3 = t2.map(a => {
									const updated = updatedTasks.find((ut) => ut.id === a.id);
									if (!updated) return a;
									return updated;
								}) as OriginalTask[];
								// New tasks.
								const t4 = [
									...t3,
									...(updatedTasks.filter((updated) => !oldTasks.find(a => a.id == updated.id))),
								];
								return t4;
							});
						});
						await api.subscribeTaskChanges({
							module: ModuleType.ProgressiveAssemblyManagement
						});
						api.addWorkShiftChangesHandler((response) => {
							const updatedWorkShifts = response.workShifts || [];
							const removedWorkShifts = response.workShiftsToRemove || [];

							setShifts((oldShifts: WorkShift[]) => {
								// Remove items.
								const oldAndExisting = oldShifts.filter(a => !removedWorkShifts.includes(a.id));
								// Update items.
								const oldAndUpdated = oldAndExisting.map(a => {
									const updated = updatedWorkShifts.find((ut) => ut.id === a.id);
									if (!updated) return a;
									return updated;
								}) as WorkShift[];

								const allNewShifts = [
									...oldAndUpdated,
									...(updatedWorkShifts.filter((updated) => !oldShifts.find(c => c.id === updated.id)) || []),
								];
								return allNewShifts;
							});
						});
						await api.subscribeWorkShiftChanges({
							module: ModuleType.ProgressiveAssemblyManagement
						});
					}

					if (modules.includes(ModuleType.SmartToolManagement)) {
						// Fetch initial state.
						// Fetch layout, item and taks states and store to state.
						const x = await api.getLayout();

						const reduceById = (x: { id: number }[]) => x.reduce((acc, y) => ({ ...acc, [y["id"]]: y }), {});
						const getAreasFromFloor = (floor: Floor) => floor.areas.map((a) => ({ floor: floor.id, ...a }));

						// Floors.
						const floors = x.floors.map((f) => ({ id: f.id, name: f.name, areas: f.areas.map((a) => a.id) }));
						logger.log(`Floors loaded, count ${floors?.length}`);

						// Areas.
						const areas = x.floors.map(getAreasFromFloor).flat();
						logger.log(`Areas loaded, count ${areas?.length}`);

						setFloors(reduceById(floors));
						setAreas(reduceById(areas));

						// Blueprint, load asynchronously.
						const image = areas.find((a) => a.type === AreaType.Image);
						if (image && image.imageId) {
							api
								.getLayoutImage({ id: image.imageId })
								.then((data) => {
									setBlueprint(data);
									logger.log("Blueprint image loaded");
								})
								.catch((e) => {
									logger.error("Failed to load blueprint image", e);
								});
						}

						// Tasks.
						const tasksResponse = await api.getTasks({
							module: ModuleType.SmartToolManagement
						});
						//TODO
						const tasks = tasksResponse.tasks.filter((t) => !t.completed);
						let transformedTasks: UiTask[] = tasks.map(transformTask);

						setTasks(reduceById(transformedTasks));
						logger.log(`Tasks loaded, count ${tasks?.length}`);
						api.addTaskChangesHandler((task) => {
							// const updatedTasks = (task.tasks || []).filter((t) => t.state !== TaskState.Done);
							const updatedTasks = task.tasks || [];
							const removedTasks = (task.tasksToRemove || []).map((x) => x.toString());
							setTasks((oldTasks: Record<TaskKey, UiTask>) => {
								const t1 = Object.entries(oldTasks);
								// Remove tasks.
								const t2 = t1.filter(([key, value]) => !removedTasks.includes(key));
								// Update tasks.
								const t3 = t2.map(([key, value]) => {
									const updates = updatedTasks.find((ut) => ut.id.toString() === key);
									if (!updates) return [key, value];
									const newEntry = {
										...value,
										...updates,
									};
									const newTuple = [key, transformTask(newEntry)];
									return newTuple;
								}) as [string, SmartToolManagementTask][];
								// New tasks.
								const t4 = [
									...t3,
									...(updatedTasks
										.filter((updated) => !oldTasks[updated.id])
										.map((updated) => [updated.id.toString(), transformTask(updated)]) || []),
								];
								return Object.fromEntries(t4);
							});
						});

						await api.subscribeTaskChanges({
							module: ModuleType.SmartToolManagement
						});

						// Items.
						const itemsResponse = await api.getItems();
						const items = itemsResponse.items;
						const transformedItems = items.map(transformItem);
						setItems(reduceById(transformedItems));
						logger.log(`Items loaded, count ${items?.length}`);
						api.addItemChangesHandler((task) => {
							const updatedItems = task.items || [];
							const removedItems = (task.itemsToRemove || []).map((x) => x.toString());
							setItems((oldItems: Record<TaskKey, Item>) => {
								const t1 = Object.entries(oldItems);
								// Remove items.
								const t2 = t1.filter(([key, value]) => !removedItems.includes(key));
								// Update items.
								const t3 = t2.map(([key, value]) => {
									const updates = updatedItems.find((ut) => ut.id.toString() === key);
									if (!updates) return [key, value];
									const newEntry = {
										...value,
										...updates,
									};
									const newTuple = [key, transformItem(newEntry)];
									return newTuple;
								}) as [string, UiTask][];
								const t4 = [
									...t3,
									...(updatedItems
										.filter((updated) => !oldItems[updated.id])
										.map((updated) => [updated.id.toString(), transformItem(updated)]) || []),
								];
								return Object.fromEntries(t4);
							});
						});

						await api.subscribeItemChanges();

						// Alerts.
						const alertsResponse = await api.getAlerts();
						const alerts = alertsResponse.alerts;
						const transformedAlerts = alerts.map(transformAlert);
						setAlerts(reduceById(transformedAlerts));
						logger.log(`Alerts loaded, count ${alerts?.length}`);
						api.addAlertChangesHandler((response) => {
							const updatedAlerts = response.alerts || [];
							const removeAlerts = (response.alertsToRemove || []).map((x) => x.toString());
							setAlerts((oldAlerts: Record<AlertKey, Alert>) => {
								const t1 = Object.entries(oldAlerts);
								const t2 = t1.filter(([key, value]) => !removeAlerts.includes(key));
								const t3 = t2.map(([key, value]) => {
									const updates = updatedAlerts.find((ut) => ut.id.toString() === key);
									if (!updates) return [key, value];
									return [
										key,
										transformAlert({
											...value,
											...updates,
										}),
									];
								}) as [string, Alert][];
								const t4 = [
									...t3,
									...(updatedAlerts
										.filter((updated) => !oldAlerts[updated.id])
										.map((updated) => [updated.id.toString(), transformAlert(updated)]) || []),
								];
								return Object.fromEntries(t4);
							});
						});
						await api.subscribeToAlertChanges();
					};
				} catch (e) {
					throwError(e);
				}
			}
			const publicApi = {
				init,
				createItem: api.createItem,
				editItem: api.editItem,
				removeItem: api.removeItem,
				setItemLed: api.setItemLed,
				createTask: api.createTask,
				editTask: api.editTask,
				updateTask: api.updateTask,
				removeTask: api.removeTask,
			};

			return publicApi;
		};

import { CSSProperties, useRef, useState } from "react";
import { useIsMounted } from "../hooks";
import { FixedSizeList, VariableSizeList } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import React from "react";

export type GetListItemsParam = { offset: number; limit: number };

export const createInfiniteList = <TItem,>({
  batchSize = 50,
  getEmptyView,
  getErrorView,
  itemSize,
  type,
  Item,
}: {
  batchSize?: number;
  getEmptyView: () => React.ReactNode;
  getErrorView: (x: { error: string | Error }) => React.ReactNode;
  Item: any;
  itemSize: number | ((x: TItem) => number);
  type: "fixed" | "variable";
}) => {
  return function WrappedInfiniteList({
    width,
    height,
    getItems,
    itemProps,
  }: {
    getItems: ({ offset, limit }: GetListItemsParam) => TItem[];
    width: number;
    height: number;
    itemProps?: any;
  }) {
    const BATCH_SIZE = batchSize;
    const [items, setItems] = useState<TItem[]>([]);
    const [error, setError] = useState<Error | string | null>(null);
    const [isNextPageLoading, setNextPageLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(true);
    const isMounted = useRef(false);
    const isItemSizeFunction = typeof itemSize === "function";
    const [start, setStart] = useState(0);
    const itemCount = hasNextPage ? items.length + 1 : items.length;
    const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

    useIsMounted(isMounted);

    const loadItems = async (_start: number) => {
      if (isNextPageLoading) return;
      const amount = BATCH_SIZE;
      setNextPageLoading(true);
      try {
        const newBatch = getItems({
          limit: BATCH_SIZE,
          offset: start,
        });

        if (!Array.isArray(newBatch)) throw Error("New batch wasn't an array");

        const itemsCount = newBatch.length;
        const newItems = [...items, ...newBatch];

        if (!isMounted.current) return;

        setStart(start + BATCH_SIZE);
        if (itemsCount < amount) {
          setHasNextPage(false);
        }
        setItems(newItems);
      } catch (err: unknown) {
        if (isMounted.current) {
          setError(err instanceof Error ? err : JSON.stringify(err));
        }
      } finally {
        if (isMounted.current) {
          setNextPageLoading(false);
        }
      }
    };

    if (!width || !height) return null;
    if (itemCount === 0 && !isNextPageLoading) return getEmptyView();
    if (error) getErrorView ? <>{getErrorView({ error: error })}</> : <span style={{ color: "red" }}>ERROR</span>;

    const ListElem: any = type === "fixed" ? FixedSizeList : type === "variable" ? VariableSizeList : null;

    if (!ListElem) throw Error("Invalid list element");

    const ItemWrapper = ({ index, style }: { index: number; style: CSSProperties }) => (
      <Item isLoading={!isItemLoaded(index)} item={items[index]} style={style} itemProps={itemProps} />
    );

    return (
      <InfiniteLoader isItemLoaded={isItemLoaded} itemCount={itemCount} loadMoreItems={loadItems} threshold={10}>
        {({ onItemsRendered, ref }) => (
          <ListElem
            itemSize={isItemSizeFunction ? (i: number) => itemSize(items[i]) : itemSize}
            height={height}
            itemCount={itemCount}
            onItemsRendered={onItemsRendered}
            ref={ref}
          >
            {ItemWrapper}
          </ListElem>
        )}
      </InfiniteLoader>
    );
  };
};

import { BatteryStatus, CreateTaskRequest, ModuleType, SmartToolManagementCreateTaskRequest, SmartToolManagementSubtaskType, SmartToolManagementTaskType } from "@noccela/dna-iot-shared";
import { useState } from "react";
import { MdOutlineSettings } from "react-icons/md";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { delay } from "../functions";
import { useTranslations } from "../hooks";
import { currentItemIdAtom, linkedTaskAtom } from "../state/items";
import { areasAtom } from "../state/map";
import { errorMessageAtom, isDetailsOpenAtom } from "../state/misc";
import { Item, ServiceProps, SmartToolManagementTask } from "../types";
import Button from "./Button";
import { renderBattery } from "./ItemListItem";
import { LastActivityTimestampFromDate } from "./LastSeenTime";
import { getStateTranslation } from "../functions";


export const TagDetailCard = ({ item, services }: { item: Item } & ServiceProps) => {
  const t = useTranslations();
  const areas = useRecoilValue(areasAtom);
  const itemId = useRecoilValue(currentItemIdAtom);
  const task = useRecoilValue(linkedTaskAtom(itemId as any));
  const setIsDetailsOpen = useSetRecoilState(isDetailsOpenAtom);
  const setError = useSetRecoilState(errorMessageAtom);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const calcCondition = () => {
    if (!item.strokeMaintenanceInterval) return 0;
    return 100 - (item.strokesSinceMaintenance * 100) / item.strokeMaintenanceInterval;
  };
  const orderMaintenance = async () => {
    try {
      const targetTimestamp = new Date();
      targetTimestamp.setDate(targetTimestamp.getDate() + 1);
      const taskToCreate: SmartToolManagementCreateTaskRequest = {
        module: ModuleType.SmartToolManagement,
        type: SmartToolManagementTaskType.Maintenance,
        itemId: item?.id,
        areaId: 20,
        targetTimestamp: targetTimestamp.toISOString().substring(0, 16),
        priority: 1,
        amountToProduce: null,
        materialCode: null,
        materialAmount: null
      };
      const res = await services.api?.createTask(taskToCreate);
      setIsDetailsOpen(false);
    } catch (e) {
      setError({
        message: t({
          id: "Error.OrderingMaintenance",
        }),
        details: JSON.stringify(e, null, 4),
      });
      console.error(e);
    }
  };

  const flashLed = async () => {
    try {
      setIsLoading(true);
      services.api?.setItemLed({ id: item.id });
      await delay(2000);
    } catch (error) {
      console.error(error);
      setError({
        message: t({
          id: "Error.FlashingLed",
        }),
        details: JSON.stringify(error, null, 4),
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col drop-shadow bg-white w-auto">
      <div className="flex flex-wrap sm:px-6 px-3 sm:py-4 py-2 border-b border-dna-vaaleanharmaa items-center">
        <MdOutlineSettings className="text-dna-kuumapinkki text-4xl sm:block hidden" />
        <h1 className="sm:ml-4 ml-2 font-bold sm:text-3xl text-xl">{item.name}</h1>
        <div className="w-auto flex flex-col sm:ml-8 ml-2">
          <p className="text-sm">{t({ id: "Item.Location" })}:</p>
          <p className="font-semibold text-sm">
            {item?.location?.areas.map((n) => Object.values(areas).find((m) => m.id === n)?.name).join(", ")}
          </p>
        </div>
        <div className="w-auto flex flex-col sm:ml-8 ml-2">
          <p className="text-sm">{t({ id: "Details.Status" })}:</p>
          <p className="font-semibold text-sm">{getStateTranslation(task, t)}</p>
        </div>
        <div className="w-auto flex flex-col sm:ml-8 ml-2">
          <p className="text-sm -mb-0.5">{t({ id: "Details.Battery" })}:</p>
          <span className="text-dna-kuumapinkki text-xl ">
            {renderBattery({ t, status: item?.batteryStatus || BatteryStatus.Unknown, renderText: true })}
          </span>
        </div>
        <div className="w-auto flex flex-col sm:ml-8 ml-2">
          <p className="text-sm">{t({ id: "Heading.Condition" })}:</p>
          <p className="text-sm font-semibold">
            {calcCondition()}% - {item.strokesSinceMaintenance || 0}/{item.strokeMaintenanceInterval || 0}
          </p>
        </div>
        <div className="flex flex-col w-auto sm:flex-row sm:space-x-4 space-x-0 sm:space-y-0 space-y-2 ml-auto justify-end items-center">
          <Button
            variant="secondary"
            text={t({ id: "Actions.FlashLed" }).toUpperCase()}
            onClick={() => {
              flashLed();
            }}
            className="text-sm sm:text-base"
            isLoading={isLoading}
          />
          <Button
            variant="primary"
            disabled={!!task}
            text={t({ id: "Actions.OrderMaintenance" }).toUpperCase()}
            className="text-sm sm:text-base"
            onClick={() => orderMaintenance()}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 divide-x divide-dna-vaaleanharmaa w-auto">
        <div className="flex sm:py-4 py-2 sm:px-8 px-4">
          <MdOutlineSettings className="text-dna-kuumapinkki hidden sm:block text-9xl sm:mr-4 mr-2" />
          <div className="flex flex-col">
            <p className="font-semibold mb-4">{t({ id: "Details.TagInfo" })}</p>
            <p>
              {t({ id: "Details.SerialNumber" })}: {item.tagSerialNumber}
            </p>
            <p>
              {t({ id: "Details.LastSeen" })} <LastActivityTimestampFromDate t={t} timestamp={item?.lastActivity} />
            </p>
          </div>
        </div>
        <div className="flex flex-col sm:py-4 py-2 sm:px-8 px-4">
          <p className="font-semibold">{t({ id: "Details.Description" })}</p>
          <p>{item.description}</p>
        </div>
      </div>
    </div>
  );
};

export default TagDetailCard;

import { atom } from "recoil";
import { ModuleType, ProgressiveAssemblyManagementSubtaskConfiguration, ProgressiveAssemblyManagementWorkShift, ProgressiveAssemblyManagementWorkShiftSubtask, Subtask, Task } from "@noccela/dna-iot-shared";
import { BackendState, ErrorMessage, Pages } from "../types";
import { ProgressiveLimits } from "../backend/types";

export const connectionStateAtom = atom<BackendState>({
  key: "backend_state",
  default: BackendState.NotConnected,
});

// Currently selected and rendered page.
export const currentPageAtom = atom<Pages>({
  key: "current_page",
  default: Pages.List,
});

export const currentModuleAtom = atom<ModuleType | null>({
  key: "current_module",
  default: null,
});

export const modulesAtom = atom<ModuleType[]>({
  key: "modules",
  default: [],
});

export const currentProgressivePhaseAtom = atom<ProgressiveAssemblyManagementSubtaskConfiguration | null>({
  key: "current_phase",
  default: null
});

export const progressivePhasesAtom = atom<ProgressiveAssemblyManagementSubtaskConfiguration[]>({
  key: "progressive_phases",
  default: []
});

export const progressiveWorkshiftsAtom = atom<ProgressiveAssemblyManagementWorkShift[]>({
  key: "progressive_shifts",
  default: []
});

export const progressiveTasksAtom = atom<Task[]>({
  key: "progressive_tasks",
  default: []
});

export const progressiveLimits = atom<ProgressiveLimits | null>({
  key: "progressive_limits",
  default: null
});

export const isEditOpenAtom = atom<boolean>({
  key: "is_edit_open",
  default: false,
});

export const isDetailsOpenAtom = atom<boolean>({
  key: "is_details_open",
  default: false,
});

export const errorMessageAtom = atom<ErrorMessage | null>({
  key: "error_message",
  default: null,
});

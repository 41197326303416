import clsx from "clsx";
import { ReactNode } from "react";
import ReactDOM from "react-dom";
import styles from "./ModalWindow.module.css";

export const ModalWindow = ({ children }: { children: ReactNode }) => {
  return ReactDOM.createPortal(
    <div className={clsx("h-full w-full flex overflow-hidden absolute top-0", styles.parent)}>{children}</div>,
    document.body,
  );
};

export default ModalWindow;

import clsx from "clsx";

export const TagIcon = ({
  fill,
  width,
  height,
  className,
  hole,
  holeFill,
}: {
  fill: string;
  width: number;
  height: number;
  className?: string;
  hole?: boolean;
  holeFill?: string;
}) => {
  const midX = Math.floor(width / 2);
  const midY = Math.floor(height / 2);
  const holeSize = Math.floor(width / 4);
  const part = Math.floor(width / 10);

  return (
    <svg
      className={clsx(className)}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <mask id="circle">
          <rect width="100%" height="100%" fill="white" />
          <circle cx={midX} cy={part * 5} r={holeSize} fill="black" />
        </mask>
      </defs>
      <path
        d="M9.96429 24L9.57857 23.7107C9.18214 23.3571 0 16.3179 0 9.96428C8.57143e-08 4.46116 4.46116 0 9.96429 0C15.4674 0 19.9286 4.46116 19.9286 9.96428C19.9286 16.3179 10.7464 23.3571 10.35 23.6571L9.96429 24Z"
        fill={fill}
        mask={hole && !holeFill ? "url(#circle)" : ""}
      />
      <path
        d="M9.96429 24L9.57857 23.7107C9.18214 23.3571 0 16.3179 0 9.96428C8.57143e-08 4.46116 4.46116 0 9.96429 0C15.4674 0 19.9286 4.46116 19.9286 9.96428C19.9286 16.3179 10.7464 23.3571 10.35 23.6571L9.96429 24Z"
        fill={fill}
        mask={hole && !holeFill ? "url(#circle)" : ""}
      />
      {hole && holeFill && <circle cx={midX} cy={part * 5} r={holeSize} fill={holeFill} />}
    </svg>
  );
};

import { identity, isNil } from "lodash";
import { atom, selector, selectorFamily } from "recoil";
import { Item, ItemFilter, ItemKey, ItemSorter, SmartToolManagementTask, TaskKey } from "../types";
import { sortByString } from "../util/misc";
import { sortByDateProp } from "../util/state";
import { tasksAtom, taskAtom } from "./tasks";

export const itemsAtom = atom<Record<ItemKey, Item>>({
  key: "items",
  default: {},
});
export const itemSorterAtom = atom<ItemSorter>({
  key: "items_sort",
  default: {
    // lastActivity: "desc",
    name: "asc",
  },
});
export const itemsOrderAtom = selector<ItemKey[]>({
  key: "items_order",
  get: ({ get }) => {
    const items = get(itemsAtom);
    const sort = get(itemSorterAtom);
    const sorted = Object.values(items).sort((a, b) => {
      if (sort.id) {
        if (sort.id === "asc") return a.id - b.id;
        if (sort.id === "desc") return b.id - a.id;
      }
      if (sort.lastActivity) {
        return sortByDateProp({ prop: "lastActivity", direction: sort.lastActivity })(a, b);
      }
      if (sort.name) {
        if (sort.name === "asc") return sortByString(a.name, b.name);
        if (sort.name === "desc") return sortByString(a.name, b.name) * -1;
      }
      return a.id - b.id;
    });
    return sorted.map((s) => s.id);
  },
});
export const itemsListAtom = selector<Item[]>({
  key: "items_list",
  get: ({ get }) => {
    const items = get(itemsAtom);
    const itemsOrder = get(itemsOrderAtom);
    return itemsOrder.map((i) => items[i]).filter(identity);
  },
});
export const itemFilterAtom = atom<ItemFilter>({
  key: "items_filter",
  default: {},
});
export const filteredItemsOrderListAtom = selector<ItemKey[]>({
  key: "items_order_list_filtered",
  get: ({ get }) => {
    const items = get(filteredItemsListAtom);
    return items.map((i) => i["id"]);
  },
});
export const filteredItemsListAtom = selector<Item[]>({
  key: "items_list_filtered",
  get: ({ get }) => {
    const items = get(itemsListAtom);
    const filter = get(itemFilterAtom);
    return items.filter((x) => {
      const text = [x.description, x.name, x.tagSerialNumber, x.type.toString()].filter(identity).join(" ").toLowerCase();
      if (filter.name && !(text || "").includes(filter.name.toLowerCase())) return false;
      return true;
    });
  },
});
export const filteredItemsAtom = selector<Record<ItemKey, Item>>({
  key: "filtered_items_atom",
  get: ({ get }) => {
    const items = get(filteredItemsListAtom);
    return Object.fromEntries(items.map((i) => [i.id, i]));
  },
});
// Currently selected.
export type CurrentItemId = ItemKey | null;
export const currentItemIdAtom = atom<CurrentItemId>({
  key: "current_item_id",
  default: null,
});
// Single item from storage by id.
export const itemAtom = selectorFamily<Item | null, ItemKey | null>({
  key: "item",
  get:
    (id) =>
      ({ get }) => {
        if (isNil(id)) return null;
        return get(itemsAtom)[id];
      },
});
export const currentItemAtom = selector<Item | null>({
  key: "current_item",
  get: ({ get }) => {
    const id = get(currentItemIdAtom);
    if (!id) return null;
    return get(itemAtom(id));
  },
});
export const linkedTasksAtom = selector<Record<ItemKey, TaskKey[]>>({
  key: "linked_tasks",
  get: ({ get }) => {
    const tasks = get(tasksAtom);
    const dict = Object.values(tasks)
      .map((task) => [task.itemId, task.id] as [number, number])
      .reduce(
        (acc, [key, itemId]) => ({
          ...acc,
          [key]: acc[key] ? [...acc[key], itemId] : [itemId],
        }),
        {} as Record<ItemKey, TaskKey[]>,
      );
    return dict;
  },
});
export const linkedTaskAtom = selectorFamily<SmartToolManagementTask | null, ItemKey | null>({
  key: "linked_task",
  get:
    (id) =>
      ({ get }) => {
        if (!id) return null;
        const links = get(linkedTasksAtom);
        const link = links[id as any];
        if (!link) return null;
        if (!link.length) return null;
        if (link.length > 1) {
          console.warn(`Item ${id} is linked to multiple tasks: ${link}`);
        }
        // TODO: What to do if there's accidentally multiple tasks?
        const taskKey = link[0];
        const task = get(taskAtom(taskKey));
        return task;
      },
});
